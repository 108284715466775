<template>
<div v-if="photo_preview" class="fixed top-0 z-10 p-12 min-h-screen w-full bg-black flex flex-col justify-center items-center">
<!-- <div class="fixed top-0 z-10 p-12 min-h-screen w-full bg-black flex flex-col justify-center items-center"> -->
    <img :src="current_photo" class=" max-w-[90vw]" />
    <span>no. of photos: {{  capturedImages.length }}</span>
    <div class=" absolute self-end w-[80%] flex flex-row justify-between bottom-[120px] left-0 right-0 mx-auto my-0">
        <button class="border bg-transparent p-3 rounded-md font-bold px-6 text-white" @click="retakePhoto">retake</button>
        <button class="btn" @click="continueKYC">continue</button>
    </div>
</div>

<transition name="formSlide">
    <!-- <div v-if="takeNewPhoto" class="flex flex-col fixed top-0 left-0 h-screen bg-[rgba(0,0,0,0.8)] dark:bg-[rgba(0,0,0,0.8)] w-full z-40 justify-center items-center p-3"> -->
    <div v-if="takeNewPhoto" class="flex flex-col fixed top-0 left-0 h-screen bg-black w-full z-40 justify-center items-center p-3">
        <button @click="uploadDocumentsForVerification">upload</button>
        <CameraComponent :use_back_cam="useBackCamera" @image-captured="handleImageCaptured"/>
    </div>
</transition>

<!-- {{  qr_link }} -->

<!-- NAVBAR... -->
<div class="flex flex-row w-full justify-between p-3 items-center dark:border-gray-600 dark:text-white border-b bg-white dark:bg-gray-800">
    <RouterLink to="/">
        <img src="../../public/apex-tek.svg" class="h-[30px] dark:hidden"/>
        <img src="../../public/apex-tek-white.svg" class="h-[30px] hidden dark:inline-block"/>
    </RouterLink>
    <button @click="returnToHome">
        <i class="bi bi-x-lg text-xl"></i>
    </button>
</div>

<div v-if="!KYC_data.status" class=" flex min-h-screen text-center justify-center items-center  dark:text-white bg-white dark:bg-gray-800 w-full overflow-y-auto py-[50px] ">
    loading...
</div>

   <!-- VERIFICATION MODAL MAIN -->
<div v-if="KYC_data.status == 'pending'" class="flex flex-col min-h-screen dark:text-white bg-white dark:bg-gray-800 w-full justify-center items-center overflow-y-auto py-[50px]">
    <!-- CONTINUE ON MOBILE -->
    <div class=" hidden md:flex text-center p-12  rounded-lg flex-col justify-center items-center">
        <h1 class="font-bold text-2xl">Please Continue verification on a mobile device</h1>
        <div class="mt-3 flex-col gap-3 flex justify-center items-center p-5">
            <div v-if="qr_link" class="p-3 bg-white border rounded-lg border-tz_blue">
                <qrcode-svg :value="qr_link" level="H" size="150"/>
            </div>
            <!-- <p class="max-w-[400px]">Scan the QR code with your mobile device or <span class="underline text-blue-500">request</span> verification link</p> -->
            <p class="max-w-[400px]">Scan the QR code with your mobile device.</p>
            <button class="btn border !bg-inherit" @click="returnToHome">Cancel</button>
        </div>
    </div>
    <div class=" md:hidden">
        <!-- VERIFICATION SCREEN 1 -->
        <div v-if="verification_step == 1" class="flex flex-col items-center p-8 min-h-[70vh] justify-between">
            <img src="../assets/images/auth_hand.png" class=" w-[200px]"/>
            <span class="font-bold text-2xl">Let's Verify Your KYC</span>
            <p class="text-center">Please follow the steps below to verify your identity</p>
            <div class=" mt-12 flex flex-col gap-6 max-w-[380px]">
                <div class="flex flex-row gap-5">
                    <i class="bi bi-person-fill"></i>
                    <div class="flex flex-col">
                        <b>Take a selfie of yourself</b>
                        <span>To match your face with the photo on your NIN card or slip</span>
                    </div>
                </div>
                <div class="flex flex-row gap-5">
                    <i class="bi bi-person-fill"></i>
                    <div class="flex flex-col">
                        <b>Take a picture of your valid NIN card/slip</b>
                        <span>To check that your personal information are correct and that the number inputted earlier matches</span>
                    </div>
                </div>
            </div>
            <button @click="verification_step++" class="btn mt-12 !px-16">Start Verification</button>
         </div>

           <!-- INPUT NIN NUMB... -->
        <div v-if="verification_step == 2" class="flex flex-col items-center p-8 min-h-[70vh] justify-between">
            <img src="../assets/images/NIN_hand_verify.png">
            <div class="flex flex-col text-center justify-center items-center">
                <span class="font-bold text-2xl">What is Your National Identification Number</span>
                <p>Please input your nin as it is shown on your nin card/slip</p>
            </div>
            <div class="mt-4">
                <input class="!bg-transparent rounded-md form_input" 
                    type="text" 
                    @input="validateNIN"
                    maxlength="11"
                    v-model="KYC.NIN" 
                    placeholder="12345678901">
            </div>
            <button :disabled="KYC.NIN.length < 11" @click="verification_step++" class="btn mt-12 !px-16">Continue</button>
        </div>

         <!-- SNAP NIN SLIP -->
        <div v-if="verification_step == 3" class="flex flex-col items-center p-8 min-h-[70vh] justify-between">
            <div class="flex flex-col justify-center items-center text-center">
                <img src="../assets/images/NIN_hand_verify.png">
                <!-- <img src="../assets/images/NIN_verify.png" class=" w-[200px]"/> -->
                <span class="font-bold text-2xl mt-6">Take a photo of your NIN clip or card</span>
                <p>Please present a valid NIN card or slip for verification</p>
            </div>
            <button @click="initiateNewSnap"  class="btn mt-12 !px-16">Continue</button>
        </div>

         <!-- TAKE A SELFIE -->
        <div v-if="verification_step == 4" class="flex flex-col items-center p-8 min-h-[70vh] justify-between">
            <div class="flex flex-col justify-center items-center">
                <span class="font-bold text-2xl">Take a Selfie</span>
                <p>Let us verify your face</p>
            </div>
            <div>
                <img src="../assets/images/person_verify.png">
            </div>
            <button @click="initiateNewSnap" class="btn mt-12 !px-16">Continue</button>
        </div>

        <!-- KYC VERIFICATION COMPLETE -->
        <div v-if="verification_step == 5" class="flex flex-col items-center p-8 min-h-[70vh] justify-between">
            <i class="bi bi-check-circle text-green-500 text-[150px]"></i>
            <div class="flex flex-col justify-center items-center">
                <span class="font-bold text-2xl">KYC Completed</span>
                <p>We wil notify you once approved </p>
            </div>
            <button :disabled="sending_docs" @click="uploadDocumentsForVerification" class="btn mt-12 !px-16">
                <span v-if="sending_docs">working...</span>
                <span v-else>Finish</span>
            </button>
        </div>
    </div>
</div>
<div v-if="KYC_data.status == 'verified'" class="flex flex-col min-h-screen dark:text-white bg-white dark:bg-gray-800 w-full justify-center items-center overflow-y-auto py-[50px]">
    <div>You are verified!</div>
</div>
<div v-if="KYC_data.status == 'in progress'" class=" text-center flex flex-col min-h-screen dark:text-white bg-white dark:bg-gray-800 w-full justify-center items-center overflow-y-auto py-[50px]">
    <div>Your KYC verification is currently being processed<br/>
         <small>you will be notified on the next status.</small>
    </div>
</div>

</template>

<script>
import Qrcode, { QrcodeCanvas, QrcodeSvg } from 'qrcode.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import CameraComponent from '@/components/CameraComponent.vue';
import axios from 'axios';
import { RouterLink } from 'vue-router';
import router from '@/router';


    export default {
        name: "KYCVerificationView",
        components: {
            SpinnerComponent,
            CameraComponent,
            Qrcode,
            QrcodeCanvas,
            QrcodeSvg,
        },
        data(){
            return{
                qr_link: '',
                sending_docs: false,
                useBackCamera: true,
                photo_preview: '',
                current_photo: '',
                takeNewPhoto: false,
                capturedImages: [],
                KYC: {
                    NIN: '',
                    documents: {
                        selfie_image: '',
                        NIN_slip: '',
                    }
                },
                verification_step: 1,
                capturedImages: [],
                capturedImage: null, // Holds the Base64 image strin
                headers: {
                    Authorization: `JWT ${localStorage.getItem('life-gaurd')}`
                },
                KYC_already_verified: false,
                KYC_data: '',
            }
        },

        methods: {
            continueKYC(){
                this.capturedImages.push(this.current_photo);
                this.verification_step += 1;
                this.photo_preview = false;
            },

            retakePhoto(){
                this.current_photo = '';
                this.takeNewPhoto = true;
            },

            initiateNewSnap(){
                this.takeNewPhoto = true;
                if(this.capturedImages.length > 0){
                    this.useBackCamera = false;
                }
            },

            returnToHome(){
                this.$router.push('/in/settings');
            },
            validateNIN(){
                this.KYC.NIN = this.KYC.NIN.replace(/[^0-9]/g, "");
            },

            handleImageCaptured(base64Image) {
                this.takeNewPhoto = false;
                this.current_photo = base64Image;
                this.photo_preview = true;
            },

            base64ToBlob(base64, mimeType) {
                return new Promise((resolve) => {
                    const byteString = atob(base64.split(",")[1]);
                    const arrayBuffer = new ArrayBuffer(byteString.length);
                    const uint8Array = new Uint8Array(arrayBuffer);

                    for (let i = 0; i < byteString.length; i++) {
                    uint8Array[i] = byteString.charCodeAt(i);
                    }

                    resolve(new Blob([arrayBuffer], { type: mimeType }));
                });
            },

            async generateQRCodeFOrMobile(){
                try{
                    const response = await axios.get(`/user/${this.$route.params.user_id}/account_verification/mobile`, { headers: this.headers });
                    this.qr_link = response.data.qr_link;
                    this.KYC_data = response.data.KYC;
                    if(response.status == 200){
                        this.KYC_already_verified = true;
                    }
                    console.log("KYC data:", response)
                }catch(error){
                    console.log("error getting qr link: ", error);
                }
            },

            async getDataFromDB(){
                try{
                    const response = await axios.get(`/user/${this.$route.params.user_id}/account_verification/mobile`, { headers: this.headers });
                    if(response.status == 200){
                        window.location.reload();
                    }
                    console.log("KYC data:", response)
                }catch(error){
                    console.log("error getting qr link: ", error);
                }
            },

            async uploadDocumentsForVerification() {
                try {
                    this.sending_docs = true;
                    const formData = new FormData();

                   // Append the NIN field to formData
                    formData.append("NIN", this.KYC.NIN);

                    // Convert Base64 to Blob for each captured image and append to FormData
                    for (let i = 0; i < this.capturedImages.length; i++) {
                    const blob = await this.base64ToBlob(this.capturedImages[i], "image/jpeg");
                    formData.append(`files`, blob, `snapshot_${i + 1}.jpg`); // Add each file with a unique name
                    }
                    // const response = await axios.post(`/user/${this.$route.params.user_id}/account_verification`, formData, { headers: this.headers });
                    const response = await axios.post(`/user/${this.$route.params.user_id}/account_verification`, formData);
                    
                    this.sending_docs = false;

                    console.log("Uploaded KYC docs:", response);
                    router.push('/in/settings')
                } catch (error) {
                    console.error("Error uploading verification docs:", error);
                    this.sending_docs = false;
                }
            },
        },

        created(){
            this.generateQRCodeFOrMobile();


            /* 
                auto refresh page for quicker inkage to folder...
            */
            if(!this.KYC_already_verified){
                setInterval(()=> {
                    this.getDataFromDB();
                }, 10000);
            }
            
        }
        
    }
</script>

<style scoped>

</style>