<template>
    <!-- <div> -->
        <!-- <div class=" w-[250px] border absolute bg-white top-9 right-3 rounded-lg p-1 hidden group-hover:block z-50 dark:bg-[#1F2A36] dark:border-gray-600"> -->
        <div  class=" w-[94vw] md:w-[250px] border bg-white rounded-lg p-1 dark:bg-[#1F2A36] dark:border-gray-600 shadow-lg">
            <div class="flex flex-col text-left gap-1">

                <!-- TOP MENU FOR USERS -->
                <RouterLink v-if="user?.role == 'user'" :to="'/in/users/' + user._id" >
                    <div class=" relative p-3 gap-3 flex flex-row justify-between cursor-pointer border-b border-gray-300 dark:border-b-gray-700">
                        <div class="flex flex-col">
                            <span v-if="user.role == 'user'">{{ username }}</span>
                            <span class="text-tz_blue text-sm">{{ email }}</span>
                            <span class="text-slate-400 capitalize">{{ user_type }}</span>
                        </div>
                        <i class="bi bi-arrow-right absolute top-[40%] right-0"></i>
                    </div>
                </RouterLink>

                <!-- TOP MENU SIDE FOR EMPLOYERS -->
                <RouterLink v-else to="/client/profile" >
                    <div class=" relative p-3 gap-3 flex flex-row justify-between cursor-pointer border-b border-gray-300 dark:border-b-gray-700">
                        <div class="flex flex-col">
                            <span>{{ user?.profile?.company_name }} XXX</span>
                            <span class="text-tz_blue text-sm">{{ email }}</span>
                            <span class="text-slate-400 capitalize">{{ user_type }}</span>
                        </div>
                        <i class="bi bi-arrow-right absolute top-[40%] right-0"></i>
                    </div>
                </RouterLink>

                <!-- MENU FOR USER ... -->
                <div v-if="sub_items && user_type == 'user'">
                    
                    <div class=" flex flex-col md:hidden">
                        <RouterLink to="/">
                            <div class="menu_item"><i class="bi bi-briefcase"></i> Work Explorer</div>
                        </RouterLink>

                        <RouterLink to="/in/saved">
                            <div class="menu_item"><i class="bi bi-bookmark-check"></i> Saved Jobs</div>
                        </RouterLink>

                        <RouterLink to="/in/messages">
                            <div class="menu_item"><i class="bi bi-chat-square-text"></i> Messages</div>
                        </RouterLink>

                        <RouterLink to="/in/contracts">
                            <div class="menu_item"><i class="bi bi-gift"></i> Contracts</div>
                        </RouterLink>
                    </div>



                    <div class=" flex flex-col">
                        <div @click="show_sub_items = !show_sub_items" class="menu_item flex flex-row justify-between items-center cursor-pointer">
                            <div class=" flex flex-row gap-2">
                                <i class="bi bi-person"></i>
                                <span>Account</span>
                            </div>
                            <span>
                                <i class="bi bi-caret-down-fill"></i>
                            </span>
                        </div>
                        
                        <!-- SHOW SUB ITEMS -->
                        <div class=" flex flex-col ml-3" v-if="show_sub_items">
                            <RouterLink to="/in/applications">
                                <div class="menu_item"><i class="bi bi-sticky"></i> My Applications</div>
                            </RouterLink>
                            
                            <RouterLink to="/in/overview">
                                <div class="menu_item"><i class="bi bi-compass"></i> Overview</div>
                            </RouterLink>

                            <RouterLink to="/in/settings">
                                <div class="menu_item"><i class="bi bi-gear"></i> Settings</div>
                            </RouterLink>
                        </div>
                    </div>
                </div>


                <!-- MENU FOR EMPLOYERS>... -->
                <div v-if="sub_items && user_type == 'employer'">
                    <div class=" flex md:hidden flex flex-col">
                        <RouterLink to="/client/dashboard">
                            <div class="menu_item"><i class="bi bi-sticky"></i> Dashboard</div>
                        </RouterLink>
                        <RouterLink to="/client/messages">
                            <div class="menu_item"><i class="bi bi-chat-square-text"></i> Messages</div>
                        </RouterLink>
                        <RouterLink to="/client/contracts">
                            <div class="menu_item"><i class="bi bi-sticky"></i> Contracts & Hires</div>
                        </RouterLink>
                    </div>

                    <div class=" flex flex-col">
                        <div @click="show_sub_items = !show_sub_items" class="menu_item flex flex-row justify-between items-center cursor-pointer">
                            <div class=" flex flex-row gap-2">
                                <i class="bi bi-person"></i>
                                <span>Account</span>
                            </div>
                            <span>
                                <i class="bi bi-caret-down-fill"></i>
                            </span>
                        </div>
                        
                        <!-- SHOW SUB ITEMS -->
                        <div class=" flex flex-col ml-3" v-if="show_sub_items">
                            <RouterLink to="/client/jobs">
                                <div class="menu_item"><i class="bi bi-briefcase"></i> My Jobs</div>
                            </RouterLink>
                            
                            <RouterLink to="/client/overview">
                                <div class="menu_item"><i class="bi bi-compass"></i> Overview</div>
                            </RouterLink>

                            <RouterLink to="/client/settings">
                                <div class="menu_item"><i class="bi bi-gear"></i> Settings</div>
                            </RouterLink>
                        </div>
                    </div>


                   
                </div>
              
                <!-- <RouterLink to="/support"> -->
                <button @click="openTicket" class="menu_item "><i class="bi bi-question-circle"></i>Submit a ticket</button>
                <!-- </RouterLink> -->
                <div class="menu_item p-3 flex flex-row gap-3 justify-between items-center">
                    <span>
                        <span class="dark:hidden">
                            <i class="pi pi-sun"></i>
                        </span>
                        <span class="hidden dark:inline-block">
                            <i class="pi pi-moon"></i>
                        </span>
                        theme
                    </span>
                    <label class="switch scale-[0.5]">
                        <input type="checkbox" @change="updateTheme()">
                        <span class="slider round"></span>
                    </label>
                </div>
                <button @click="logout" class="mt-3 font-bold p-3 rounded-md bg-gray-500 bg-opacity-20 hover:bg-red-600 hover:text-white"><i class="bi bi-box-arrow-right"></i> Logout</button>
            </div>
        </div>
    <!-- </div> -->
</template>
<script>
import axios from 'axios'

import InputSwitch from 'primevue/inputswitch';

export default {
    name: "UserDropDownMenu",
    components: {
        InputSwitch,
    },
    props: {
        username: String,
        email: String,
    },
    data(){
        return{
            sub_items: true,
            user_type: '',
            user: '',
            headers: {
                Authorization: `JWT ${localStorage.getItem('life-gaurd')}`
            },
            theme: false,
            show_sub_items: false,
        }
    },
    methods:{
        openTicket(){
            this.$emit('open-tickets');
        },

        logout(){
            localStorage.removeItem("life-gaurd");
            this.$router.push('/');
            window.location.reload();
        },

        async getUserData(){
            const headers = this.headers;
            try{
                const response = await axios.get(`${this.api_url}/user`,  { headers });
                this.user = response.data.user;
                this.user_type =response.data.user.role;
            }catch(error){
                console.log("user data error from drop down menu:", error)
            }
        },

        updateTheme(){
            if(localStorage.theme == 'light'){
                // dark_mode(){
                    localStorage.setItem('theme', 'dark');
                    document.documentElement.classList.add("dark");
                    this.theme = true;
                // };
            } else {
                localStorage.setItem('theme', 'light');
                document.documentElement.classList.remove("dark");
                this.theme = false;
            }
        },

        
    },
    created(){
        this.getUserData();
        if(localStorage.theme == 'light'){
            this.theme = false;
        } else {
            this.theme = true;
        }
    }
}
</script>
<style scoped>
i{
    @apply mr-2
}
    .menu_item{
        @apply hover:bg-tz_light_blue p-3 rounded-md text-left
    }


    .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

</style>