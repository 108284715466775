<template>
    <div>
        <section id="introduction">
        <h2>1. Introduction</h2>
        <p>This Billing Policy details the billing practices and procedures for all products and services offered by Apex Technologies ("we," "us," or "our"). By purchasing our products or services, you agree to comply with this policy. This document aims to provide clear and transparent guidelines regarding billing, payment methods, and dispute resolution.</p>
    </section>

    <section id="billing-cycle">
        <h2>2. Billing Cycle</h2>
        <ul>
            <li><strong>Initial Charges:</strong> All charges related to job postings are applied at the time you submit your work order request.</li>
            <li><strong>Monthly Billing for Extended Projects:</strong> If the duration of your work order extends beyond a month, our payment system will automatically process charges monthly.</li>
            <li><strong>Calculation of Monthly Charges:</strong> The total budgeted amount will be divided by the project length to determine the monthly charge. For example, a ₦600,000 project over six months results in ₦100,000 per month.</li>
            <li><strong>Payment Failure Consequences:</strong> Freelancers assigned to your project will halt work if payment is not processed.</li>
        </ul>
        <p><strong>Annual/Monthly Billing for Premium Services:</strong> Additional charges for premium services can be billed monthly or annually.</p>
    </section>

    <section id="payment-methods">
        <h2>3. Payment Methods</h2>
        <ul>
            <li>Debit Cards: Visa and MasterCard are accepted.</li>
            <li>Bank Transfers: Payments can be made directly via bank transfer.</li>
        </ul>
    </section>

    <section id="payment-terms">
        <h2>4. Payment Terms</h2>
        <ul>
            <li><strong>Due Date:</strong> Payments are due at the time of posting your work order.</li>
            <li><strong>Currency:</strong> All payments must be made in Naira.</li>
            <li><strong>Non-Payment Consequences:</strong> Unpaid job postings may be suspended or terminated.</li>
        </ul>
    </section>

    <section id="refunds-disputes">
        <h2>5. Refunds and Disputes</h2>
        <h3>Refunds:</h3>
        <ul>
            <li>Refunds are issued at our discretion after assessing specific cases.</li>
        </ul>
        <h3>Billing Disputes:</h3>
        <ul>
            <li>Notify us within 48 hours of an invoice date for dispute resolution.</li>
        </ul>
    </section>

    <section id="policy-changes">
        <h2>6. Changes to Billing Policy</h2>
        <p>We reserve the right to modify this Billing Policy at any time. Changes take effect immediately upon posting on our website. Continued use of our services implies acceptance of changes.</p>
    </section>

    <section id="effective-date">
        <h2>7. Effective Date</h2>
        <p>This Billing Policy is effective as of 06/10/2024.</p>
    </section>

    <section id="payment-processor">
        <h2>8. Payment Processor</h2>
        <p>Billing is processed by our third-party partner QorePay. By using their platform, you agree to their terms and privacy policy. Visit <a href="https://www.qorepay.com" target="_blank">QorePay</a> for more details.</p>
    </section>

    <section id="freelancer-payment-policy">
        <h2>9. Payment Policy for Freelancers</h2>
        <ul>
            <li>A 12.99% commission is deducted from payments received by freelancers.</li>
            <li>Employers review and approve freelancer submissions before payments are processed.</li>
            <li>Payments are processed and deposited into freelancers' accounts after approval, with confirmation provided.</li>
        </ul>
    </section>

    <footer>
        <p>If you have any questions, contact us at <a href="mailto:support@ApexTeks.com">support@ApexTeks.com</a>.</p>
        <p>Apex Technologies (A Company of DIGITALWAVE SOFT-TECH INNOVATION LTD)</p>
        <p>Effective Date: 06/12/2024</p>
    </footer>
    </div>
</template>

<script>
    export default {
        name: "BillingPolicyText",
    }
</script>

<style scoped>
    h3{
        @apply font-bold text-lg mt-3 text-blue-500
    }

    h2{
        @apply font-bold text-xl mt-3 text-blue-700
    }

    p{
        @apply font-medium
    }

    /* p{
        @apply p-4
    } */
</style>