<template>
    <div>
        <header>
        <h1>Cookies Policy</h1>
        <p>Effective Date: 16/12/2024</p>
    </header>

    <section>
        <p>This Cookies Policy explains how ApexTeks ("we," "us," or "our") uses cookies and similar technologies on our website to enhance user experience, analyze website performance, and provide personalized services. By using our website, you consent to the use of cookies as described in this policy.</p>
    </section>

    <section>
        <h2>What Are Cookies?</h2>
        <p>Cookies are small text files placed on your device (such as a computer, smartphone, or tablet) when you visit our website. They serve various purposes, including remembering user preferences, improving website functionality, and tracking user behavior.</p>
    </section>

    <section>
        <h2>Types of Cookies We Use</h2>
        <h3>Essential Cookies:</h3>
        <p>These cookies are necessary for the basic functioning of our website and cannot be disabled. They include session cookies that help maintain your session and ensure proper navigation.</p>

        <h3>Functional Cookies:</h3>
        <p>These cookies enhance the usability of our website by remembering your preferences, such as language settings, and providing personalized features.</p>

        <h3>Analytics Cookies:</h3>
        <p>We use analytics cookies to gather information about how visitors interact with our website, such as the pages visited, time spent on each page, and referral sources. This data helps us analyze website performance and improve user experience.</p>

        <h3>Advertising Cookies:</h3>
        <p>Third-party advertising partners may place cookies on your device to deliver targeted ads based on your interests and browsing behavior. These cookies enable us to display relevant advertisements and measure ad effectiveness.</p>
    </section>

    <section>
        <h2>Managing Cookies</h2>
        <p>You have the option to accept or reject cookies through your web browser settings. Most web browsers allow you to control cookie preferences, including blocking or deleting cookies from specific websites. However, please note that disabling certain cookies may impact the functionality and user experience of our website.</p>
    </section>

    <section>
        <h2>Your Consent</h2>
        <p>By continuing to use our website, you consent to the use of cookies as outlined in this Cookies Policy. You can change your cookie preferences at any time by adjusting your browser settings or using the cookie consent tool provided on our website.</p>
    </section>

    <section>
        <h2>Third-Party Cookies</h2>
        <p>Some cookies on our website may be placed by third-party service providers, such as analytics platforms and advertising networks. These third parties may collect information about your browsing activity across different websites to deliver personalized content and advertisements. We do not have control over third-party cookies, and their use is subject to their respective privacy policies.</p>
    </section>

    <section>
        <h2>Updates to this Policy</h2>
        <p>We may update this Cookies Policy from time to time to reflect changes in our cookie practices or legal requirements. We encourage you to review this policy periodically for any updates. Your continued use of our website after such changes constitutes your acceptance of the revised Cookies Policy.</p>
    </section>

    <section class="contact-info">
        <h2>Contact Us</h2>
        <p>If you have any questions or concerns about our use of cookies, please contact us at <a href="mailto:support@ApexTeks.com">support@ApexTeks.com</a>. For more information about your privacy rights and data protection practices, please refer to our <a href="privacy-policy.html">Privacy Policy</a>.</p>

        <p><strong>Apex Technologies</strong> (A Company of DIGITALWAVE SOFT-TECH INNOVATION LTD)</p>
        <p>Email: <a href="mailto:support@ApexTeks.com">support@ApexTeks.com</a></p>
    </section>
    </div>
</template>

<script>
    export default {
        name: "CookiesPolicyText",
    }
</script>

<style scoped>
    h3{
        @apply font-bold text-lg mt-3 text-blue-500
    }

    h2{
        @apply font-bold text-xl mt-3 text-blue-700
    }

    p{
        @apply font-medium
    }
</style>