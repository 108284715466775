<template>

<div :class="['navbar', { 'navbar-scrolled': hasScrolled }]" class="bg-transparent fixed -top-4 z-50 start-0 w-full  md:bg-inherit">
<!-- <div :class="hasScrolled ? 'navbar-scrolled':'navbar'" class=" border border-red-500 mb-5"> -->
    <div class="p-3 flex flex-row justify-between items-center mx-auto my-0 max-w-screen-2xl">
        <!-- <SiteLogo/> -->
        <RouterLink to="/">
            <img :src="hasScrolled ? `${require('../../public/apex-tek.svg')}`:`${require('../../public/apex-tek-white.svg')}`" class=" h-[35px] md:h-[50px] ">
        </RouterLink>
        <div class=" bg-white md:bg-transparent w-full flex-col md:flex-row justify-around items-center md:flex absolute z-10 top-16 left-0 flex md:top-0 md:relative pb-6 md:p-0 opacity-0 md:h-fit md:opacity-100 transition-all duration-500 ease-out shadow-lg md:shadow-none" :class="mobile_menu ? 'h-screen opacity-100':'h-0 opacity-0'">
        <!-- <div class="bg-white w-full flex-col md:flex-row justify-around items-center md:flex relative flex md:top-0 md:relative pb-6 md:p-0 opacity-0 md:h-fit md:opacity-100 transition-all duration-500 ease-out shadow-lg md:shadow-none" :class="mobile_menu ? 'h-screen opacity-100':'h-0 opacity-0'"> -->
            <div class=" flex text-center flex-col md:flex-row gap-6 md:items-center md:mx-auto md:my-0 p-5 md:p-0">
                <RouterLink to="/" class="" :class="isHome ? 'border-tz_blue border-b-4  text-tz_blue':''">Home</RouterLink>
                <!-- <RouterLink to="/jobs" class="" :class="isJobs ? 'border-tz_blue border-b-4  text-tz_blue':''"> FInd Job</RouterLink> -->
                <RouterLink to="/about" class="" :class="isAbout ? 'border-tz_blue border-b-4  text-tz_blue':''">About Us</RouterLink>
                <RouterLink to="/FAQ" :class="isFAQ ? 'border-tz_blue border-b-4 text-tz_blue':''">FAQ</RouterLink>
                <a target="_blank" href="https://blog.apexteks.com">Blog</a>
            </div>
            <div class="flex flex-row gap-3 p-5 md:p-0">
                <RouterLink to="/login">
                    <button :class="hasScrolled ? 'text-black':'text-tz_blue md:text-white'" class="nav_btn rounded-full w-fit  hover:text-tz_blue hover:bg-tz_light_blue">Login</button>
                </RouterLink>
                <RouterLink to="/register">
                    <button class="nav_btn rounded-full bg-tz_blue hover:bg-tz_dark_blue text-white">Sign Up</button>
                </RouterLink>
            </div>
        </div>
        <button class="text-4xl md:hidden" @click="[mobile_menu = !mobile_menu, scrollToHeight()]">
            <i class="bi bi-x text-3xl" v-if="mobile_menu"></i>
            <i class="bi bi-list text-3xl" v-else></i>
        </button>
    </div>
</div>

    <section class=" big-bg-image overflow-hidden relative text-white -top-[200px]">
        <div class="bg-black bg-opacity-70 h-full w-full absolute top-0 left-0"></div>

        <div class="md:container mx-auto relative z-10 mt-[200px]">
            <!-- HEADER AREA -->
            <div class="flex flex-col flex-wrap md:flex-row gap-4  text-white pt-5 md:text-left md:justify-start text-center items-center justify-center">
                <div class=" flex flex-col gap-3 flex-1 h-[450px] justify-center mt-12">
                    <div class="flex flex-col">
                        <h1 class=" text-[70px] leading-[80px] font-bold">Find your Dream Job, Hire the right talent.</h1>
                        <p>At Apex Technologies, we serve as a comprehensive marketplace, facilitating seamless connections between companies seeking tech expertise and skilled professionals looking for temporary contract opportunities.</p>
                        
                    </div>
                    <!-- MINI SEARCH -->
                   
                    <div class="flex flex-row gap-2 rounded-full bg-white md:pl-6 p-1 overflow-hidden max-w-[600px] mt-6 text-black justify-between">
                        <div class=" hidden md:flex">
                            <Popover v-model:open="open" class=" md:w-[40%]">
                                <PopoverTrigger as-child class=" flex items-center flex-row justify-end">
                                <Button
                                    variant="outline"
                                    role="combobox"
                                    :aria-expanded="open"
                                    class="w-fit justify-start"
                                >
                                    {{ category_name ? job_categories.find((category) => category.name === category_name)?.name : 'All Categories...' }}

                                    <ChevronsUpDown class="ml-2 h-4 w-4 shrink-0 opacity-50 mr-5" />
                                </Button>
                                </PopoverTrigger>
                                <PopoverContent class="w-[200px] p-0">
                                <Command v-model="category_name">
                                    <CommandInput placeholder="Search Categories..." />
                                    <CommandEmpty>No category found.</CommandEmpty>
                                    <CommandList>
                                    <CommandGroup>
                                        <CommandItem
                                        v-for="category in job_categories"
                                        :key="category.name"
                                        :value="category.name"
                                        @select="open = false"
                                        >
                                        <Check
                                            :class="cn(
                                            'mr-2 h-4 w-4',
                                            category_name === category.name ? 'opacity-100' : 'opacity-0',
                                            )"
                                        />
                                        {{ category.name }}
                                        </CommandItem>
                                    </CommandGroup>
                                    </CommandList>
                                </Command>
                                </PopoverContent>
                            </Popover>
                        </div>
                        <div class="md:w-[70%] flex flex-row items-center">
                            <input type="text" placeholder="Search for jobs, skills, or services" class="search p-4 w-full rounded-md bg-white !border-none !outline-none text-black">
                            <button class="p-4 px-8 rounded-full bg-tz_blue text-white self-end">Search</button>
                        </div>
                       
                    </div>
                </div>
                <div data-aos="fade-up" data-aos-duration="1000">
                    <img  src="../assets/images/landing_page/girl.png" class=" flex-1 max-w-full h-auto relative -bottom-8 transform-none"/>
                </div>
            </div>
        </div>
       
    </section>

   

 <!-- SECTION 2 -->
    <section class="p-5">
        <div class="container mx-auto py-8">
            <div class="flex flex-row justify-between items-end">
                <div>
                    <h2 class=" font-bold text-2xl">Our Categories</h2>
                    <small>Get some Inspirations from 86K+ skills</small>
                </div>
                <RouterLink to="/jobs">
                    <button>View More <i class=" bi bi-arrow-right"></i></button>
                </RouterLink>
            </div>

            <!-- CATEGORIES AREA -->
            <div class="flex flex-row flex-wrap gap-3 mt-8">

                <!-- CATEGORIES -->
                <div data-aos="fade-up" data-aos-duration="600" :data-aos-delay="100 * index" v-for="(category, index) in job_categories.slice(0,12)" :key="index" class="flex-1 flex flex-col gap-3 p-4 px-8 bg-tz_light_blue rounded-lg w-fit h-40 md:min-w-fit min-w-full justify-center border border-transparent hover:border-blue-500 transition-all duration-100 delay-100">
                    <img :src="require(`../assets/images/landing_page/job_categories/${index + 1}.png`)" width="50px">
                    <span class=" font-bold text-lg">{{ category.name }}</span>
                    <span>0 Jobs</span>
                </div>
            </div>
        
        </div>
    </section>

    <!-- SECTION 3 -->
    <section class="p-5">
        <div class="container mx-auto vector-bg bg-[#13544e] rounded-3xl flex flex-row gap-3 md:p-12 p-8 text-white">
            <div class=" flex flex-col lg:flex-row gap-6">
                <div class="flex flex-col gap-6 justify-around flex-1">
                    <small>Explore New Life</small>
                    <h2 class=" text-[30px] font-bold">Don’t just find. Be found put your CV in front of great employers</h2>
                    <p>Establishing Trustworthy Interactions. Ensuring Secure Communication Between Employers and Technical Professionals on the Platform</p>
                    <RouterLink to="/login">
                        <button class=" rounded-full bg-tz_blue text-white px-8 w-fit p-3">Post Job Now <i class=" bi bi-arrow-right"></i></button>
                    </RouterLink>
                    <div class=" flex flex-col md:flex-row gap-3">
                        <div data-aos="fade-up" data-aos-duration="100" data-aos-delay="300" class=" flex flex-col flex-1 gap-2 bg-white rounded-lg p-5 text-black justify-center items-center text-center">
                            <span class=" font-bold text-3xl">{{ count.users }}+</span>
                            <span>Onboarded freelancers</span>
                        </div>
                        <div  data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500" class=" flex flex-col flex-1 gap-2 bg-white rounded-lg p-5 text-black justify-center items-center text-center">
                            <span class=" font-bold text-3xl">{{ count.employers }}+</span>
                            <span>Onboarded Employers</span>
                        </div>
                        <div   data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600" class=" flex flex-col flex-1 gap-2 bg-white rounded-lg p-5 text-black justify-center items-center text-center">
                            <span class=" font-bold text-3xl">{{ count.jobs }}+</span>
                            <span>Total Jobs Posted</span>
                        </div>
                    </div>
                </div>

                <div data-aos="fade-up" data-aos-duration="1000">
                    <img src="../assets/images/landing_page/cta-img.png" class="flex-1 w-full max-w-full h-auto"/>
                </div>
            </div>

        </div>
    </section>

   

    <!-- SECTION 5 -->
    <section class="p-5 bg-tz_light_blue">
        <div class="container mx-auto flex flex-col flex-wrap lg:flex-row gap-3 py-12 items-center" data-aos="fade-up" data-aos-duration="2000">
            <div class="flex flex-col gap-6 flex-1">
                <h2 class=" text-[30px] font-bold">A whole world of freelance talent at your fingertip</h2>
                <div class=" flex flex-col gap-3 m">
                    <div class="flex flex-row gap-3 items-center p-5 rounded-xl hover:bg-white">
                        <img src="../assets/images/landing_page/tab-icon-1.png">
                        <div class=" flex flex-col gap-2">
                            <span class=" text-xl font-bold">Proof of Quality Works</span>
                            <span>As an employer, you're in control! Only you can ensure the quality of service meets your high standards. All job approvals go through you—exclusively.</span>
                        </div>
                    </div>
                    <div class="flex flex-row gap-3 items-center p-5 rounded-xl hover:bg-white">
                        <img src="../assets/images/landing_page/tab-icon-2.png">
                        <div class=" flex flex-col gap-2">
                            <span class=" text-xl font-bold">No Cost Until You Hire</span>
                            <span>As soon as your job is posted, we're on it. Rest assured, your money remains untouched—no charges will be made until the job is completed. Should anything go awry, worry not, your funds are safeguarded and a refund is just a click away! </span>
                        </div>
                    </div>
                    <div class="flex flex-row gap-3 items-center p-5 rounded-xl hover:bg-white">
                        <img src="../assets/images/landing_page/tab-icon-3.png">
                        <div class=" flex flex-col gap-2">
                            <span class=" text-xl font-bold">Safe and Secure Payment Both</span>
                            <span>Rest easy knowing that all your transactions on our website are end-to-end encrypted. In other words, your financial safety and security are our top priorities. </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="relative lg:flex-2 flex-1" data-aos="fade-up" data-aos-duration="1000">
                <img src="../assets/images/landing_page/v-card-1.png" class=" absolute top-[30%] -right-10 md:inline-block hidden">
                <img src="../assets/images/landing_page/v-card-2.png" class=" absolute bottom-[30%] -left-10 md:inline-block hidden">
                <img src="../assets/images/landing_page/f-girl.png" class="max-w-full overflow-clip"/>
            </div>
        </div>
    </section>


    <!-- SECTION 6 -->
    <section class="p-5">
        <div class="container mx-auto py-8">
            <div class="flex flex-row justify-between items-end">
                <div>
                    <h2 class=" font-bold text-2xl">Top Freelancers</h2>
                    <small>Get some Inspirations from 86K+ skills</small>
                </div>
                <button>View More <i class=" bi bi-arrow-right"></i></button>
            </div>

            <!-- CATEGORIES AREA -->
            <div class="flex flex-row flex-wrap gap-6 mt-8">
<!-- {{ top_freelancers }} -->
                <!-- CATEGORIES -->
                <div v-if="top_freelancers" v-for="(user, index) in top_freelancers" :key="index" class="flex-1 flex flex-col gap-1 p-8 bg-white rounded-2xl w-fit md:min-w-fit min-w-full justify-center items-center shadow-xl">
                    <img :src="user.profile.image_url" class="rounded-full !size-[180px]"/>
                    <span class=" font-bold text-2xl mt-3">{{ user.username }}</span>
                    <span>{{ user.profile.title }}</span>
                    <div class=" flex flex-row items-center gap-2 justify-center">
                        <i class="bi bi-star-fill"></i>
                        <span class=" font-bold">0.0</span>
                        <span>(0 Reviews)</span>
                    </div>
                    <RouterLink target="_blank" :to="'/users/'+user._id">
                        <button class="btn !rounded-full flex flex-row !items-center gap-3">View Pofile <i class=" bi bi-arrow-right"></i></button>
                    </RouterLink>
                </div>
            </div>
        
        </div>
    </section>

    <!-- SECTION 7 -->
    <section class="p-5">
        <div class="container mx-auto vector-bg bg-[#13544e] rounded-3xl flex flex-row gap-3 md:p-12 p-6 text-white mt-20">
            <div  class=" flex flex-row justify-between relative w-full">
                <div data-aos="fade-up" data-aos-duration="3000" class="flex flex-col gap-5 p-6 flex-1">
                    <h2 class=" text-[30px] font-bold">Find the talent needed to get your business growing.</h2>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour.</p>
                    <RouterLink to="/login">
                        <button class=" rounded-full bg-tz_blue text-white px-8 w-fit p-3">Get Started <i class=" bi bi-arrow-right"></i></button>
                    </RouterLink>
                </div>

                <div class="relative flex-1 hidden md:block" data-aos="fade-up" data-aos-duration="1000">
                    <img src="../assets/images/landing_page/men-women.png" class="absolute right-0 -bottom-12 w-full max-w-[400px] h-auto"/>
                </div>
            </div>

        </div>
    </section>

    <section class=" p-5">
        <div class="container mx-auto flex items-center justify-center flex-col gap-5 my-12">
            <h2 class="FAQ font-bold text-[50px]">FAQs</h2>
            <div class="w-full mt-5 border p-8 border-tz_blue rounded-xl gap-3 flex flex-col">
                
                
                <div data-aos="fade-up" :data-aos-duration="100 * question_id" v-for="(question, question_id) in generalFAQs" :key="question_id" class=" border border-tz_blue p-3 rounded-lg">
                    <button @click="show_answer(question_id)" class="flex flex-row justify-between mt-3 w-full text-left">
                        <h4 class="font-bold text-lg max-w-90%">{{ question.text }}</h4>
                        <span @click="show_answer(question_id)" class=" text-tz_blue">
                            <i v-if="answer_active[question_id]" class="bi bi-dash"></i>
                            <i v-else class="bi bi-plus"></i>
                        </span>
                    </button>
                    <p v-if="answer_active[question_id]" class="w-[90%]" v-html="question.answer"></p>
                   
                </div>
                
            </div>
            <RouterLink to="/FAQ">
                <p class="text-blue-500 underline">See more <i class=" bi bi-arrow-right"></i></p>
            </RouterLink>
            
        </div>
    </section>

    <Footer class=" vector-bg"/>
    
</template>

<script>
import HomeNavbar from '@/components/HomeNavbar.vue';
import Footer from '@/components/TheFooter.vue';
// animate onscroll library
import AOS from 'aos'
import 'aos/dist/aos.css'

import axios from 'axios';
import { RouterLink } from 'vue-router';


import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '@/components/ui/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { cn } from '@/lib/utils'
import { Check, ChevronsUpDown } from 'lucide-vue-next'



export default {
    name: 'LandingPageView',
    components: {
        HomeNavbar,
        Footer,
        Command,
        CommandEmpty,
        CommandGroup,
        CommandInput,
        CommandItem,
        CommandList,
        Popover,
        PopoverContent,
        PopoverTrigger,
        Check,
        ChevronsUpDown,
    },

    data() {
        return{
            hasScrolled: false,
            mobile_menu: false,
            alert_banner: true,

            open: false,
            category_name: '',
            cn,
            frameworks: [
                { value: 'next.js', label: 'Next.js' },
                { value: 'sveltekit', label: 'SvelteKit' },
                { value: 'nuxt', label: 'Nuxt' },
                { value: 'remix', label: 'Remix' },
                { value: 'astro', label: 'Astro' },
            ],
            generalFAQs: [
                    { text: "Is there a free trial available?",
                    answer:"Currently, ApexTeks is entirely free for both companies and freelancers. There are no hidden fees or charges for signing up and posting jobs on the website. If there are any changes in the future, all ApexTeks users will be notified through their preferred communication channels. However, freelancers are subject to a 12.99% charge on every completed work order. " },

                    { text: "How do I find jobs on the platform?", 
                    answer: "After registering on ApexTeks, you can personalize your job preferences, and relevant job listings will appear on your feed. Moreover, you can utilize the search bar to actively browse through a variety of jobs that match your expertise."},

                    {
                        text: "How can I upload my CV to the platform?",
                        answer: "Simply go to your profile page and click on the <Resume> button. You'll find option to upload and view your CV "
                    },

                    {
                        text: "How can I apply for a job listing ",
                        answer: "To apply for a job listing as a Talent on the platform, follow these steps:<ol> <li>1. Log in to your account and navigate to the Work Explorer page. </li> <li>2. Browse through the active job listings to find a job that interests you.</li><li> 3. Click on the job listing to view more details.</li><li> 4. Use the <i>apply</i> here button provided on the job listing page to start and complete the application process. </li></ol>"
                    },

                    {
                        text: "How can I save job listings for later ",
                        answer: `<div>To save job listings for later viewing: <ol type="1"><li>1. Go to the Work Explorer page on the platform.</li><li>2. Look for the job listing you want to save. </li><li>3. Next to the job listing, you'll see a save icon. </li><li>4. Click on this icon to save the job for later. </li><li>5. If you wish to remove a job from your saved list, click on the save icon again to deselect it and remove it from your saved list. </li></ol></div>`
                    },

                    {
                        text: "How do I post a job listing as an employer ",
                        answer: "To post a job listing as an employer, follow these steps: <ol><li>1. Go to the Employers Console on the platform.</li><li> 2. Click on the <i>Create Job</i> link located in the navigation bar. This action will direct you to the Create Job page.</li><li> 3. Follow the provided instructions and steps on the Create Job page to successfully create and post your job listing.</li> </ol>"
                    },

                    {
                        text: "Are there any fees associated with employers posting jobs? ",
                        answer: "No, there are no charges for posting jobs on ApexTeks. The platform is free for both employers and talents. However, there is a 12.99% charge applied to every completed work order."
                    },
                    {
                        text: "Does Apex-Teks have any undisclosed fees or charges? ",
                        answer: "ApexTeks operates with transparency, and all potential fees are explicitly outlined. Double confirmation is necessary for crucial actions related to payments to prevent inadvertent actions. "
                    },
                    {
                        text: "What is the billing process? ",
                        answer: "Billing is mainly directed at employers, who are charged the full job listing fee upon a successful job posting. This is facilitated through an escrow payment system. In case a job is canceled 24 hours before its scheduled start, the entire payment is refunded. However, if the work order is canceled within 24 hours of starting, 20 percent of the total payment made will be deducted. Employers are required to provide a valid debit card for payments, and they can manage payment options in the billing information page. All relevant information regarding billing actions is provided for transparency and clarity. "
                    },
                    {
                        text: "How often do I get paid as a freelancer?",
                        answer: "At Apex Technologies, freelancers are paid on a weekly basis. Payments are made for the previous week's work once the submitted work order is reviewed, cleared, and approved by the employer. After approval, the payment is processed and deposited into the freelancer's account within a few business days."
                    }

            ],
            answer_active: [],
            job_categories: [],
            top_freelancers: [],
            count: {
                users: 0,
                employers: 0,
                jobs: 0,
            }
        }
    },

    methods: {
        scrollToHeight() {
            // Scroll to 500px from the top
            window.scrollTo({
                top: 600, // Change this value to your desired height
                behavior: 'smooth', // Smooth scrolling effect
            });
        },
        handleScroll(){
            this.hasScrolled = window.scrollY > 500;
            console.log("window scroll: ", window.scrollY)
        },
        show_answer(index){
            this.answer_active[index] = !this.answer_active[index];
        },

        async getAllCounts(){
            try{
                const response = await axios.get('/admin/count/all');
                console.log("counts: ", response);
              /*  const users = response.data.count.users
               const employers = response.data.count.employers
               const jobs = response.data.count.jobs
               this.count.users = (users / 1_000).toFixed(1)
               this.count.employers = (employers / 1_000).toFixed(1)
               this.count.jobs = (jobs / 1).flat */

               this.count = response.data.count;

            }catch(error){
                console.log("counts error: ", error);
            }
        },

        async getAllJobCategories(){
            try{
                this.loading_cats = true;
                const response = await axios.get('/jobs/categories/all');
                this.job_categories = response.data.categories;
                this.loading_cats = false;
            }catch(error){
                // this.toast.error(error.response.data.message);
                this.loading_cats = false;
            }
        },


        async getTopFreelancers(){
            try{
                this.loading_freelancers = true;
                const response = await axios.get('/admin/users/all_public');
                this.top_freelancers = response.data.users.reverse();
                this.loading_freelancers = false;
                console.log("users: ", response)
            }catch(error){
                this.loading_freelancers = false;
                console.log("erro getting user:", error)
            }
        }
    },
    mounted(){
        window.addEventListener('scroll', this.handleScroll);
    },
    unmounted(){
        window.removeEventListener('scroll', this.handleScroll);
    },
    created() {
        AOS.init();

        this.getAllJobCategories();
        this.getTopFreelancers();
        this.getAllCounts();
    }

};
</script>

<style scoped>
.search{
    border: none !important;
    outline: 1px solid transparent !important;
}

.vector-bg{
    background: url('../assets/images/landing_page/cta-bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.big-bg-image{
    background: black;
    background: url('../assets/images/landing_page/bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.formSlide-enter-active, .formSlide-leave-active {
    transition: all 0.5s;
    opacity: 1;
}
.formSlide-enter-from, .formSlide-leave-to {
    opacity: 0;
    padding-top: 10px;
}

/* 
NAVBAR
*/
.navbar {
  position: sticky;
  top: 0;
  width: 100%;
  padding: 1rem 2rem;
  transition: background-color 0.5s ease, box-shadow 0.5s ease;
  background-color: transparent ;
  box-shadow: none;
  z-index: 1000 ;
  color: white;
}

.navbar-scrolled {
    color: black;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


.nav_btn{
        @apply px-5 py-2 font-medium;
    }

    .nav_link{
        @apply p-3 rounded-md hover:bg-tz_light_blue font-medium hover:font-medium;
    }

    .text-sm{
        font-size: 12px !important;
    }
    .btn{
        @apply bg-tz_blue hover:bg-tz_dark_blue text-white py-3 px-5 rounded-md font-medium w-52 border border-transparent
    }
    .slide_btn{
        @apply border border-tz_blue p-3 rounded-md text-tz_blue font-medium text-xl hover:bg-tz_blue hover:text-black;
    }

    .sb_active{
        @apply bg-tz_blue text-white
    }
</style>