<template>
<!-- NAVBAR... -->
<div class="flex flex-row w-full justify-start p-3 items-center border-b ">
    <img src="../../../public/apex-tek.svg" class="h-[30px]"/>
</div>


  <!-- PROFILE IMAGE UPLOAD MODAL -->
<Modal :name="'Upload a profile image'"v-model:modal_active="profile_image_menu">
            <template #body>
                <div class="flex flex-row flex-wrap gap-5 p-8 justify-center items-center">
                    <div class="flex flex-col">
                        <cropper
                            ref="cropper"
                            class="cropper h-[300px] w-[300px] bg-gray-400 rounded-full"
                            :src="image.src"
                            :stencil-component="$options.components.CircleStencil"
                            :stencil-props="{
                                handlers: {},
                                movable: false,
                                resizable: false,
                                aspectRatio: 1,
                                handlerClasses: {
                                    default: 'handler',
                                },
                                previewClass: 'preview'
                            }"
                            :debounce="false"
                            :stencil-size="{
                                width: 280,
                                height: 280
                            }"
                            :resize-image="{
                                adjustStencil: false
                            }"
                            image-restriction="stencil"
                            @change="change"
                        />
                        <div v-if="image.src" class=" flex flex-row justify-center mt-3 gap-3">
                            <button @click="zoomIn" class="btn"><i class="bi bi-zoom-in"></i></button>
                            <button @click="zoomOut" class="btn"><i class="bi bi-zoom-out"></i></button>
                        </div>
                    </div>
                    <div class="flex flex-col gap-3">
                        <h1 class="text-2xl ">Show Employers<br/> the best version of yourself!</h1>
                        <div  class="flex flex-row gap-3 mt-6">
                            <preview class="bg-gray-400 rounded-full"
                                :width="150"
                                :height="150"
                                :image="result.image"
                                :coordinates="result.coordinates"
                            />
                            <preview class="rounded-full bg-gray-400"
                                :width="75"
                                :height="75"
                                :image="result.image"
                                :coordinates="result.coordinates"
                            />
                            <preview class="rounded-full bg-gray-400"
                                :width="37.5"
                                :height="37.5"
                                :image="result.image"
                                :coordinates="result.coordinates"
                            />
                        </div>
                        <p class="text-blue-500">Must be an actual photo of you (showing your face particularly).<br/>
    Logos, clip-art, group photos, and digitally-altered images are not allowed.</p>
                    </div>
                
                </div>
            </template>
            <template #footer>
                <div class="flex flex-row gap-3">
                    <input type="file" ref="file" @change="loadImage($event)" class="hidden" accept="image/*">
                    <button class="border border-tz_blue p-3 rounded-md" @click="$refs.file.click()">Change image </button>
                    <button :disabled="image_uploading" class="btn" @click="uploadProfileImage">
                        <span v-if="!image_uploading">Save photo</span>
                        <span v-else>Working...</span>
                    </button>
                </div>
            </template>
</Modal>



<div class="flex flex-col  min-h-screen">
        <!-- ONBOARD FLOW NAV -->
    <div class="flex flex-row overflow-x-auto z-10 sticky top-0 bg-white">
        <div :key="index" v-for="(step, index) in onboarding_steps" class="onboard_nav" :class="index <= this.step ? 'active_onboard':''">
            <i class="bi" :class="step.icon"></i>
            <div>{{ step.name }}</div>
        </div>
    </div>
        <!-- ONBOARD STAGE TRACKER -->
        <!--  <div class=" md:min-h-screen flex md:flex-col md:justify-around items-start text-left md:w-[40%] bg-gray-50 p-5 px-12">
             <div class="flex flex-col gap-5">
                <div class="flex flex-row gap-3" :class="step.done ? 'text-gray-600':'text-gray-300'" v-for="(step, index) in onboarding_steps" :key="index">
                    <i class="bi bi-check-circle-fill"></i>
                    <span>{{ step.name }}</span>
                </div>
             </div>
           
         </div> -->
        <form @submit.prevent="updateUserProfile" class=" w-full flex flex-col justify-center items-center !min-h-screen">
           
            <div class="flex flex-col max-w-[500px] p-12 md:p-0 gap-3">
                    <!-- PERSONAL DETAILS -->
                    <div v-if="step == 0">
                        <h1 class="font-bold text-3xl">Personal Details</h1>
                        <p>Lets get to know you more, kindly provide all information required below.</p>
                        <div class=" flex flex-row gap-3 mt-6">
                            <div class="w-full">
                                <label for="firstname">firstname</label>
                                <input class="form_element flex-1" type="text" name="firstname" id="firstname" minlength="2" maxlength="35" v-model="user_form.firstname" placeholder="John">
                            </div>
                            <div class="w-full">
                                <label for="lastname">lastname</label>
                                <input class="form_element flex-1" type="text" name="lastname" id="lastname" minlength="2" v-model="user_form.lastname" placeholder="Doe">
                            </div>
                        </div>
                        <div class="form-section">
                            <div class="form-control">
                                <label for="title">profile title</label>
                                <input class="form_element" type="text" name="title" id="title" minlength="12" maxlength="35" v-model="user_form.profile.title" placeholder="Technical Copywriter">
                            </div>
                            <div class="form-control">
                            
                                <label for="bio">profile bio</label>
                                <!-- <Textarea v-model="value" rows="5" cols="30" class="rounded-md" /> -->
                                <textarea @keydown="checkBioLength" class="form_element min-h-[150px]" type="text" name="bio" id="bio" minlength="15" v-model="user_form.profile.bio" :class="bio_error ? 'border-red-500 !outline-red-500':''" placeholder="A very brief and descriptive bio"></textarea>
                                <span v-if="bio_error" class="text-red-500"> <i class="bi bi-exclamation-triangle-fill mr-2"></i>atleast 100 characters required ({{ user_form.profile.bio.length  }})</span>
                            </div>
                        </div>
                        
                       
                        <div class="flex flex-row gap-3">
                            <!-- <button type="button" @click="nextFormPage()"class="bg-tz_blue text-white p-3 rounded-md w-full">Next</button> -->
                            <button type="button" @click="completeProfile_1" :disabled="!user_form.profile.title || !user_form.profile.bio || bio_error || loading" class="bg-tz_blue text-white p-3 rounded-md w-full">
                                <span v-if="loading">working...</span>
                                <span v-else>next</span>
                            </button>
                        </div>
                        
                    </div>

                    <!-- CONTACT DETAILS -->
                    <div v-if="step == 1">
                        <h1 class="font-bold text-3xl">Contact Details</h1>
                        <p>This will strictly be used for record keeping & reference purposes.</p>
                        <div class="form-section">
                            <div class="form-control">
                                <label for="phone">phone</label>
                                <input class="form_element border" 
                                type="text" 
                                @input="validatePhone" 
                                name="phone" 
                                id="phone"
                                maxlength="11"
                                :class="{ 'border-red-500': !isValid && user_form.length > 0}"   
                                v-model="user_form.profile.phone" 
                                placeholder="081 2345 67890">
                                <small class="text-red-500" v-if="!isValid && user_form.profile && user_form.profile.phone.length > 0">please enter a valid phone</small>
                            </div>

                            <div class="form-control">
                                <label for="phone">social (profile link for facebook, twitter, instagram, etc.)</label>
                                <input class="form_element" type="text" name="social" id="social" v-model="user_form.profile.social" placeholder="https://facebook.com/johndoe">
                            </div>
                        </div>
                        <div class="flex flex-row gap-3">
                            <button type="button" class="p-3 border border-tz_blue rounded-md px-6 text-tz_blue" @click="step -= 1">Back</button>
                            <button type="button" @click="completeProfile_2" :disabled="!user_form.profile.phone.length > 0 || !user_form.profile.social || loading" class="btn w-full">
                                <span v-if="loading">working...</span>
                                <span v-else>Next</span>
                            </button>
                        </div>
                    </div>
           
                    <!-- SKILLS & EXPERTISE -->
                    <div v-if="step == 2">
                        <h1 class="font-bold text-3xl">What Skills do you have</h1>
                        <p>Your skillset helps personalize your experience, by displaying on your explorer page, jobs related to you.</p>
                        <div class="flex flex-col gap-3 mt-12">
                           

                            <!-- TEST TEST TEST -->
                            <span class=" mt-3 font-bold">My Skills</span>
                            <div class="p-4 flex flex-row flex-wrap gap-2 bg-gray-50">
                                <button type="button" v-for="category in user_form.preffered_job_types"  @click="addSkill(category)" class=" border rounded-lg flex-1s flex flex-row items-center justify-center gap-3 px-3 py-1 cursor-pointer hover:bg-gray-50">
                                    <span>{{ category }}</span>
                                    <span class=" size-5 rounded-full hover:bg-gray-100 flex justify-center items-center"><i class="bi bi-x"></i></span>
                                </button>
                            </div>

                            <span  class="mt-3 font-bold">Available Skillsets</span>
                            <small class=" text-orange-500">Maximum of 5 selectable skills</small>
                            <div class=" flex flex-row flex-wrap gap-2 p-3 max-h-[250px] overflow-y-auto mb-4">
                                
                                <button @click="addSkill(category.name)" type="button" v-for="category in job_categories" :class="is_selected(category.name) ? 'bg-blue-500 text-white hover:bg-blue-500':'hover:bg-gray-50'" class=" border rounded-lg flex-1s flex flex-row items-center justify-center gap-3 px-3 py-1 cursor-pointer font-light ">
                                    <span>{{ category.name }}</span>
                                    <span class=" size-5 rounded-full flex justify-center items-center"><i class="bi bi-plus"></i></span>
                                </button>
                            </div>

                            <div class="flex flex-row gap-3">
                                <button type="button" class="p-3 border border-tz_blue rounded-md px-6 text-tz_blue" @click="step -= 1">Back</button>
                                <button type="button" @click="completeProfile_3" :disabled="user_form.preffered_job_types.length == 0 || loading" class="btn w-full">
                                    <span v-if="loading">working...</span>
                                    <span v-else>Next</span>
                                </button>
                            </div>
                            
                        </div>
                    </div>

                    <!-- ADDRESS & LOCATION -->
                    <div v-if="step == 3">
                        <h1 class="font-bold text-3xl">Add Your Address</h1>
                        <p>Get access to jobs available in your location easily</p>
                        <div class="flex flex-row gap-3 mt-12">

                        
                            <div class="flex-1 flex flex-col">
                                <label for="firstname">State</label>
                                <select class="form_element " v-model="user_form.profile.location.state">
                                    <option value="" disabled>Select state</option>
                                    <option class="p-3" v-for="(state, index) in NaijaStates.states()" :key="index" :value="state">{{ state }}</option>
                                </select>
                            </div>

                            <div class="flex-1 flex flex-col">
                                <label for="firstname">LGA</label>
                                <select class="form_element " v-model="user_form.profile.location.LGA">
                                    <option value="" disabled>Select LGA</option>
                                    <option v-if="user_form.profile.location.state" class="p-3" v-for="lga in NaijaStates.lgas(user_form.profile.location.state).lgas" :value="lga">{{ lga }}</option>
                                </select>
                            </div>

                            
                        </div>

                        <div class="form-section">
                            <label for="city">City</label>
                            <input class="form_element" type="text" name="city" id="city" v-model="user_form.profile.location.city">
                        </div>

                        <div class="form-section">
                            <div class="form-control">
                                <label for="address">address line</label>
                                <input class="form_element" type="text" name="address" id="address" v-model="user_form.profile.location.address" placeholder="No. 27 John Doe Avenue, block 123">
                            </div>
                        </div>
                    
                        <div class="flex flex-row gap-3">
                            <button type="button" class="p-3 border border-tz_blue rounded-md px-6 text-tz_blue" @click="step -= 1">Back</button>
                            <button type="button" @click="completeProfile_4" :disabled="!user_form.profile.location.state || !user_form.profile.location.LGA || !user_form.profile.location.city || !user_form.profile.location.address || loading" class="btn w-full">
                                <span v-if="loading">working...</span>
                                <span v-else>Next</span>
                            </button>
                        </div>
                    
                    </div>
               
                    <!-- PROFILE IMAGE -->
                    <div v-if="step == 4">
                        <h1 class="font-bold text-3xl text-center">Let us see your face</h1>
                        <p class="text-center">Selected image would be use as your accounts profile picture. You can still set another image afterwards.</p>
                        <div class="form-section justify-center items-center py-12">
                            <div v-if="user.profile.image_url" :style="`background-image: url(${user.profile.image_url})`" class=" group relative size-52 rounded-full bg-cover">
                                <div class="bg-black absolute top-0 bottom-0 h-full w-full rounded-full hidden justify-center items-center opacity-70 group-hover:flex cursor-pointer text-white" @click="profile_image_menu = !profile_image_menu">
                                    <i class="bi bi-camera text-2xl"></i>
                                </div>
                            </div>
                            
                            <div v-else class=" group relative size-52 rounded-full flex justify-center items-center bg-gray-200 my-12">
                                <div class="bg-black absolute top-0 bottom-0 h-full w-full rounded-full hidden justify-center items-center opacity-90 group-hover:flex cursor-pointer text-white" @click="profile_image_menu = !profile_image_menu">
                                    <i class="bi bi-camera text-2xl"></i>
                                </div>
                                <i class="bi bi-person-fill text-[100px]"></i>
                            </div>
                        </div>

                        <div class="flex flex-row gap-3">
                            <button type="button" class="p-3 border border-tz_blue rounded-md px-6 text-tz_blue" @click="step -= 1">Back</button>
                            <!-- <button type="button" @click="updateUserProfile" :disabled="!user_form.profile.phone || !user_form.profile.social" class="btn w-full">Next</button> -->
                            <button type="button" @click="step++" :disabled="!user.profile.image_url" class="btn w-full">
                                <span v-if="updating_profile">working...</span>
                                <span v-else>Complete</span>
                            </button>
                        </div>
                    </div>

                    <!-- ONBOARDING COMPLETE -->
                    <div class="flex flex-col" v-if="step == 5">
                        <img src="../../assets/images/congrats.png" class=""/>
                        <h1 class="font-bold text-3xl text-center">Onboarding Completed</h1>
                        <div class=" mt-8">
                            <img src="../../assets/images/onboarding_complete.png" class=" max-h-[300px]">
                        </div>
                       
                        <RouterLink to="/in/jobs">
                            <button type="button" class="btn w-full mt-6">Proceed to job explorer</button>
                        </RouterLink>
                    </div>
                

            </div>
        </form>
</div>
    <MiniFooter/>
</template>
<script>
import TemplateView from '../TemplateView.vue';
import nigerianStates from '@/utils/states.json';
import LoaderButton from '@/components/LoaderButton.vue';
import axios from 'axios';
import FullPageModal from '@/components/FullPageModal.vue';
import DismissableAlert from '@/components/DismissableAlert.vue';

import { useToast } from 'vue-toastification'

import NaijaStates from 'naija-state-local-government';

import SiteLogo from '@/components/SiteLogo.vue';
import MiniFooter from '@/components/MiniFooter.vue';

import MultiSelect from 'primevue/multiselect';
import Textarea from 'primevue/textarea';
import { isValid } from 'date-fns';
import Modal from '@/components/Modal.vue';
import { CircleStencil, Cropper, Preview } from 'vue-advanced-cropper';
import { RouterLink } from 'vue-router';

export default {
    name: "ProfileStepView",
    components: { 
        TemplateView, 
        LoaderButton, 
        FullPageModal, 
        DismissableAlert,
        SiteLogo,
        MiniFooter, 
        MultiSelect,
        Textarea,
        Modal,

        Cropper,
        CircleStencil,
        Preview,
    },

    data(){
        return{
            profile_image_menu: false,
            isValid: true,
            onboarding_steps: [
                {name: "Personal Details", done: true, icon: "bi-person-fill"},
                {name: "Contact Details", done: false, icon: "bi-person-lines-fill"},
                {name: "Skill Set", done: false, icon: "bi-person-fill-gear"},
                {name: "Address & Location", done: false, icon: "bi-geo-alt-fill"},
                {name: "Profile Image", done: false, icon: "bi-person-square"},
            ],
            toast: useToast(),
            step: 0,
            user: '',
            alerts: [],
            loading: false,
            job_categories: [
                {name: "plumbing"},
                {name: "tiling"},
            ],
            user_form: {
                preffered_job_types: [],
                firstname: '',
                lastname: '',

                location: {
                    LGA: '',
                    city: '',
                    state: '',
                    address: '',
                },

                profile: {
                    title: '',
                    bio: '',
                    location: {
                        LGA: '',
                        city: '',
                        state: '',
                        address: '',
                    },
                    phone: '',
                    social: '',
                    },
            },

            nigerianStates,
            NaijaStates,
            headers: {Authorization: `JWT ${localStorage.getItem('life-gaurd')}`},

            profile_is_complete: '',
            bio_error: false,
            loading_cats: false,

            /* 
            PROFILE IMAGE MODSSS
            */
            result: {
                coordinates: null,
                image: null,
            },
            image: {
				src: '',
				type: null
			},
            current_zoom: 0,
            max_zoom: 5,

            ppicture: '',
            image_uploading: false,
        }
    },

    methods: {

        validateFile(event) {
            const file = event.target.files[0];
            const allowedExtensions = ["image/png", "image/jpeg", "image/jpg"];
            this.toast.error("wrong file type submitted.");
            const maxSize = 5 * 1024 * 1024; // 5MB

            // Check file type
            if (!allowedExtensions.includes(file.type)) {
            th
            this.errorMessage = "Only .png, .jpeg, or .jpg formats are allowed.";
            this.toast.error(this.errorMessage);
            this.selectedFile = null;
            return;
            }

            // Check file size
            if (file.size > maxSize) {
            this.errorMessage = "File size must be less than 5MB.";
            this.toast.error(this.errorMessage);
            this.selectedFile = null;
            return;
            }

            // Clear errors if the file is valid
            this.errorMessage = "";
            this.selectedFile = file;
        },

        updatePimage(event){
            this.ppicture = event;
        },

        validatePhone(){
            this.user_form.profile.phone = this.user_form.profile.phone.replace(/[^0-9]/g, "");
            // const phoneRegex = /^[0-9]{10,15}$/;
            this.isValid = this.user_form.profile.phone.length === 11;
        },
        is_selected(name){
            return this.user_form.preffered_job_types.includes(name)
        },

        addSkill(skill_name){
           
           if (this.user_form.preffered_job_types.length >= 5) {
               this.user_form.preffered_job_types.pop(); // Remove the last selected item if the limit is exceeded
           }
           if(this.user_form.preffered_job_types.includes(skill_name)){
               this.user_form.preffered_job_types.splice(this.user_form.preffered_job_types.indexOf(skill_name), 1 )
           } else {
               this.user_form.preffered_job_types.push(skill_name);
           }
        },


       /* 
        PROFILE IMAGE UPLOAD FUNCTIONS...
       */
       change({ coordinates, image }) {
			// console.log(coordinates, canvas, image);
            this.result = {
				coordinates,
				image
			};
		},
        // FUNCTIONS FOR ADVANCED IMAGE CROPPER..
        zoomIn() {
            if(this.current_zoom < this.max_zoom){
                this.$refs.cropper.zoom(1.2);
                this.current_zoom += 1;
            }
		},

        zoomOut() {
                this.$refs.cropper.zoom(0.2);
                this.current_zoom = 0;
		},

        crop() {
			const { canvas } = this.$refs.cropper.getResult();
			canvas.toBlob((blob) => {
				// Do something with blob: upload to a server, download and etc.
			}, this.image.type);
		},

		reset() {
			this.image = {
				src: null,
				type: null
			}
		},

        getMimeType(file, fallback = null) {
            const byteArray = (new Uint8Array(file)).subarray(0, 4);
            let header = '';
            for (let i = 0; i < byteArray.length; i++) {
            header += byteArray[i].toString(16);
            }
            switch (header) {
                case "89504e47":
                    return "image/png";
                case "47494638":
                    return "image/gif";
                case "ffd8ffe0":
                case "ffd8ffe1":
                case "ffd8ffe2":
                case "ffd8ffe3":
                case "ffd8ffe8":
                    return "image/jpeg";
                default:
                    return fallback;
            }
        },

		loadImage(event) {
            this.validateFile(event);

			// Reference to the DOM input element
			const { files } = event.target;
			// Ensure that you have a file before attempting to read it
			if (files && files[0]) {
				// 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
				if (this.image.src) {
					URL.revokeObjectURL(this.image.src)
				}
				// 2. Create the blob link to the file to optimize performance:
				const blob = URL.createObjectURL(files[0]);
				
				// 3. The steps below are designated to determine a file mime type to use it during the 
				// getting of a cropped image from the canvas. You can replace it them by the following string, 
				// but the type will be derived from the extension and it can lead to an incorrect result:
				//
				this.image = {
				   src: blob,
				   type: files[0].type
				}
				
				// Create a new FileReader to read this image binary data
				const reader = new FileReader();
				// Define a callback function to run, when FileReader finishes its job
				reader.onload = (e) => {
					// Note: arrow function used here, so that "this.image" refers to the image of Vue component
					this.image = {
						// Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
						src: blob,
						// Determine the image type to preserve it during the extracting the image from canvas:
						type: this.getMimeType(e.target.result, files[0].type),
					};
				};
				// Start the reader job - read file as a data url (base64 format)
				reader.readAsArrayBuffer(files[0]);
			}
		},

        async uploadProfileImage() {
            const { canvas } = this.$refs.cropper.getResult();
            const headers = this.headers;
            this.image_uploading = true;
            try {
                if (canvas) {
                    const form = new FormData();

                    canvas.toBlob(async blob => {
                        const mimeType = this.image.type; // Use the MIME type determined earlier
                        form.append('image', blob, `${this.user.firstname}_${this.user.lastname}-${this.user._id}-dp.${mimeType.split('/')[1]}`); // Append the blob with original file extension
                        try {
                            const response = await axios.patch(`${this.api_url}/profile/image`, form, { headers });
                            this.image_uploading = false;
                            console.log("profile image: ", response);
                            this.profile_image_menu = !this.profile_image_menu;
                            this.toast.success("profile image changed successfully");
                            // fetch profile picture..
                            this.getUserData();
                            // window.location.reload();
                        } catch (error) {
                            this.profile_image_menu = !this.profile_image_menu;
                            this.toast.error("Error uploading profile image");
                            console.error("Error uploading profile image:", error);
                            this.image_uploading = false;
                        }
                    }, this.image.type); // Pass original MIME type to toBlob
                   
                }

            } catch (error) {
                console.error("Error uploading profile image:", error);
                this.toast.error(error.response.data.message);
            }
        },
        /* 
            PROFILE IMAGE EDIT FUNCTIONS ENDS HERE...
        */

        onSelectionChange(event) {
            // Restrict the number of selected items
            if (this.user_form.preffered_job_types.length > 5) {
                this.user_form.preffered_job_types.pop(); // Remove the last selected item if the limit is exceeded
            }
        },

        nextFormPage(){
            // if(this.step == 0){
                this.step += 1
            // }
        },

        checkBioLength(){
            if(this.user_form.profile.bio.length <= 100){
                this.bio_error = true;
            } else {
                this.bio_error = false
            }
        },

        async completeProfile_1(){
         
            try{
                this.loading = true;
                const form = {
                    firstname: this.user_form.firstname,
                    lastname: this.user_form.lastname,
                    title: this.user_form.profile.title,
                    bio: this.user_form.profile.bio
                }
                const response = await axios.patch(`${this.api_url}/user/complete_profile_1`, form, { headers: this.headers });
                console.log("response: ", response);
                this.step = response.data.step;
                this.loading = false;
            }
            catch(error){
                // display any possible error here...
                console.log("error updating profile: ", error);
                this.loading = false;
            }
        },

        async completeProfile_2(){
            try{
                this.loading = true;
                const form = {
                    phone: this.user_form.profile.phone,
                    social: this.user_form.profile.social
                }
                const response = await axios.patch(`${this.api_url}/user/complete_profile_2`, form, { headers: this.headers });
                console.log("response: ", response);
                this.step = response.data.step;
                this.loading = false;
            }
            catch(error){
                // display any possible error here...
                console.log("error updating profile: ", error);
                this.loading = false;
            }
        },

        async completeProfile_3(){
            try{
                this.loading = true;
                const form = {
                    skills: this.user_form.preffered_job_types
                }
                const response = await axios.patch(`${this.api_url}/user/complete_profile_3`, form, { headers: this.headers });
                console.log("response: ", response);
                this.step = response.data.step;
                this.loading = false;
            }
            catch(error){
                // display any possible error here...
                console.log("error updating profile: ", error);
                this.loading = false;
            }
        },
        
        async completeProfile_4(){
            try{
                this.loading = true;
                const form = {
                   LGA: this.user_form.profile.location.LGA,
                   state: this.user_form.profile.location.state,
                   city: this.user_form.profile.location.city,
                   address: this.user_form.profile.location.address
                }
                const response = await axios.patch(`${this.api_url}/user/complete_profile_4`, form, { headers: this.headers });
                console.log("response: ", response);
                this.step = response.data.step;
                this.loading = false;
            }
            catch(error){
                // display any possible error here...
                console.log("error updating profile: ", error);
                this.loading = false;
            }
        },

        async getAllJobCategories(){
            try{
                this.loading_cats = true;
                const response = await axios.get('/jobs/categories/all');
                this.job_categories = response.data.categories;
                this.loading_cats = false;
            }catch(error){
                this.toast.error(error.response.data.message);
                this.loading_cats = false;
            }
        },

        async getUserData(){
            try{
                const response = await axios.get(`${this.api_url}/user`, { headers: this.headers });
                const user = response.data.user;
                this.user = response.data.user;
                this.user_form.firstname = user.firstname;
                this.user_form.lastname = user.lastname;

                // PREFILL FORMS >>>>
                user.profile.title ? this.user_form.profile.title = user.profile.title : null;
                user.profile.bio ? this.user_form.profile.bio = user.profile.bio : null;
                user.profile.phone ? this.user_form.profile.phone = user.profile.phone : null;
                user.profile.social ? this.user_form.profile.social = user.profile.social : null;
                user.preffered_job_types ? this.user_form.preffered_job_types = user.preffered_job_types : null;
                user.profile.location ? this.user_form.profile.location = user.profile.location : null;

                this.step = response.data.user.profile.current_step;
                // prefill form...
                console.log("getting user data: ", response)
            }catch(error){
                // display toast..
                console.log("error getting user data:", error)
                this.toast.error(error.response.data.message);
            }
        },

        async getPublicUserData(){
            this.loading = true;
            try{
                const response = await axios.get(`${this.api_url}/user/${this.$route.params.user_id}`);
                this.user = response.data.user;
                console.log("user data: ", this.user)
            }catch(error){
                console.log("error fetching public user data", error);
                this.loading = true;
            }
        },
    },
    created(){
        this.getUserData();
        this.getAllJobCategories()
    },
    mounted(){
       
    }
}
</script>
<style scoped>
    .form-section{
        @apply mt-5
    };

    .form_element{
        @apply rounded-md p-3 w-full border
    }

  /*   button{
        @apply font-bold
    }

    button:disabled{
        @apply bg-gray-200 text-gray-500
    } */



    .form_section{
        @apply flex flex-col md:flex-row min-h-96 w-4/5 mt-20 rounded-xl
    }

    .formSlide-enter-active, .formSlide-leave-active {
        transition: all 0.8s;
        margin-left: 0px;
        opacity: 0.8;
    }
    .formSlide-enter-from, .formSlide-leave-to {
        opacity: 0;
        margin-left: -80%;
    }


    .onboard_nav{
        @apply flex flex-row gap-3 flex-1 border-b-[8px] p-3 items-center justify-center min-w-[200px] text-gray-700 duration-100 transition-all
    }
    .active_onboard{
        @apply text-tz_blue border-b-tz_blue
    }
</style>