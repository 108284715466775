<template>
<!-- PASSWORD 2FA FOR FUNDS WITHDRAWAL -->
<div v-if="requested_withdrawal" class="flex flex-col fixed top-0 left-0 h-screen bg-[rgba(0,0,0,0.8)] dark:bg-[rgba(0,0,0,0.8)] w-full z-40 justify-center items-center">
    <form @submit.prevent="checkPassword" class="flex flex-col bg-white dark:bg-black rounded-lg p-12 gap-3">
        <h1 class="text-xl ">Please input your password to continue</h1>
        <input name="password" id="password" type="password" class="form_input" v-model="fwp_password">
        <div class="flex flex-row gap-3">
            <button type="button" @click="requested_withdrawal = !requested_withdrawal" class="bg-gray-100 dark:bg-gray-600 p-3 rounded-md font-bold">cancel</button>
            <button type="submit" class="btn w-full" :disabled="fwp_password == ''">continue</button>
        </div>
    </form>
</div>


<!-- withdrawal alert -->
<div v-if="withdraw_requested" class="flex flex-col fixed top-0 left-0 h-screen bg-[rgba(0,0,0,0.8)] dark:bg-[rgba(0,0,0,0.8)] w-full z-40 justify-center items-center">
    <div class="bg-white dark:bg-gray-800 rounded-lg flex flex-col justify-center items-center text-center p-5">
        <div class=" p-5">
            <i class="bi bi-check-circle-fill text-4xl text-green-500"></i>
            <h1 class="font-bold text-xl">Withdrawal successful </h1>
            <p class="text-gray-500">
                Your withdrawal request of NGN {{ payout_options.amount }} has been sent,<br/> 
                you will receive your funds in the specified bank account<br/> once the withdrawal has been processed.
            </p>
            <button @click="reloadPage()" class="btn mt-3">Continue</button>
        </div>
    </div>
</div>


    <PageTitle>Settings</PageTitle>
    <div class="h-full flex flex-col relative">
        <FullPageLoading v-if="loading"/>
       
        <div class=" tab flex ps-2 flex-row flex-wrap gap-2 bg-white dark:bg-[#1F2A36] border-b dark:border-gray-500 sticky top-0">
 
            <RouterLink :to="{path: '/client/settings', query: { tab: `profile` } }">
                <button :class="active_tab == 2 ? 'active_tab':''" class="tab_button">Profile & Account</button>
            </RouterLink>

            <RouterLink :to="{path: '/client/settings', query: { tab: `wallets` } }">
                <button :class="active_tab == 5 ? 'active_tab':''" class="tab_button">Wallets & payments </button>
            </RouterLink>

            <RouterLink :to="{path: '/client/settings', query: { tab: `notifications` } }">
                <button :class="active_tab == 3 ? 'active_tab':''" class="tab_button">Notifications</button>
            </RouterLink>

            <RouterLink :to="{path: '/client/settings', query: { tab: `verifications` } }">
                <button :class="active_tab == 4 ? 'active_tab':''" class="tab_button">ID & Verification</button>
            </RouterLink>
            
        </div>


        <div class=" flex flex-col p-3 gap-5">
            

            <div v-if="active_tab == 2" class="section">
                <form @submit.prevent="updateUserData" class=" mt-3">
                    <h1 class="font-bold mb-3 text-lg">General Information</h1>
                    <div class="flex flex-row gap-3 mb-3">
                        <div class="w-3/6 flex flex-col">
                            <label for="firstname">firstname</label>
                            <!-- <input class="form_input" type="name" name="firstname" id="firstname" :value="user.firstname" disabled>? -->
                            <div class="form_input">{{ user.firstname }}</div>
                        </div>

                        <div class="w-3/6 flex flex-col">
                            <label for="firstname">lastname</label>
                            <!-- <input class="form_input" type="name" name="lastname" id="lastname" :value="user.lastname" disabled> -->
                            <div class="form_input">{{ user.lastname }}</div>
                        </div>
                    </div>
                    <div class="flex flex-col w-full">
                        <label for="email">Email</label>
                        <div class="form_input" type="email" name="email" id="email" :value="user.email">{{  user.email }}</div>
                    </div>
                    <div class="flex flex-col w-full mt-3">
                        <label for="email">Phone Number</label>
                        <input v-if="user.profile" class="form_input" type="phone" name="phone" id="phone" v-model="user.profile.phone">
                    </div>
                    <button class="btn mt-3" type="submit">Update</button>
                </form>

          
          
                <!-- PASSWORD AND SECURITY -->
                <form class="" @submit.prevent="updatePassword">
                
                    <h1 class="font-bold mb-3 text-lg mt-3">Password & Security</h1>
                    <!-- GOOGLE SET PASSWOWRD ALERT -->
                    <div v-if="user?.password?.length < 5" class="flex items-center p-4 mb-4 text-sm text-orange-800 border border-orange-300 rounded-lg bg-orange-50 dark:bg-gray-800 dark:text-orange-400 dark:border-orange-800" role="alert">
                        <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                        </svg>
                        <span class="sr-only">Info</span>
                        <div>Please setup a new password to enable you perform secured transactions.</div>
                    </div>


                    <span class="mb-6">Change account password</span>
                    <div class="flex flex-col max-w-sm" v-if="user?.password?.length > 5">
                        <label for="email">Current Password</label>
                        <input class="form_input" type="password" name="password" id="password" v-model="security.password" required>
                    </div>

                    <div class=" relative flex flex-col gap-3 justify-center max-w-sm mt-3">

                        <div v-if='passHint && passwordValidation.errors.length > 0' class='hints relative pt-6'>
                            <!-- <button @click="{passHint = !passHint; passwordValidation.errors.length = 0}" type="button" class=" absolute top-2 right-2"><i class="bi bi-x-lg"></i></button> -->
                            <small class="text-orange-600" v-for='error in passwordValidation.errors'>
                                <i class="bi bi-exclamation-circle"></i> {{ error }}<br/>
                            </small>
                        </div>

                        <div class="tz_form_control">
                            <label for="password">New password</label>
                            <input @click="passHint = true" class="form_input" type="password" name="password" id="password" placeholder="a very strong password" v-model="security.new_password" required>
                        </div>
                        <div class="tz_form_control">
                            <label for="password">New password confirmation</label>
                            <input class="form_input" type="password" name="password_confirmation" id="password_confirmation" placeholder="Confirm your password" v-model="security.new_password_confirmation" required>
                        </div>
                        <span v-if="security.new_password != security.new_password_confirmation" class=" text-red-500">passwords dont match</span>
                    </div>


                    <div class="text-red-500 py-3" type="danger" v-if="password_errors">
                        <i class="bi bi-exclamation-triangle-fill mr-3"></i>
                        <span>{{  password_errors }}</span>
                    </div>
                    <button  v-if="user?.password?.length > 5" type="button" @click="updatePassword" class="btn mt-3" 
                        :disabled="!passwordValidation.valid 
                        || security.new_password_confirmation.length < 1 ">Change Account Password</button>
                    <button v-else type="button" @click="updatePassword(true)" class="btn mt-3" 
                    :disabled="!passwordValidation.valid 
                    || security.new_password != security.new_password_confirmation
                    || security.new_password_confirmation.length < 1">Set Account Password</button>
                </form>


                <!-- DELETE ACCOUNT -->
                <div class=" mt-3 pb-3">
                    <h1 class="font-bold mb-3 text-lg mt-3">Account & Profile</h1>
                     <!-- ACCON DELETE ALERT -->
                     <div class="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
                        <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                        </svg>
                        <span class="sr-only">Info</span>
                        <div class=" flex flex-col">
                            <strong>Warning</strong>
                            <span>Deleting your account cannot be undone. Any personally identifiable information will be deleted and any in-progress data will be lost.</span>
                        </div>
                    </div>
                    
                    <button disabled type="submit" class=" bg-red-500 text-white hover:bg-red-700 rounded-md p-3 mt-3">Delete Account</button>
                </div>
            </div>

            <form @submit.prevent="updateUserData" v-if="active_tab == 3" class="section mb-3">
                <h1 class="font-bold mb-3 text-lg">Notification Settings</h1>
                <!-- <div class=" flex flex-row items-center justify-between justify-center">
                    <div class="flex flex-col">
                        <span class="font-bold">Contract Notifications</span>
                        <span class="text-sm w-72 text-gray-400">Allow ApexTeks send you email notifications for contract actions and updates.</span>
                    </div>
                    
                    <label class="inline-flex items-center cursor-pointer">
                        <input type="checkbox" value="" class="sr-only peer" @change="updateUserData" v-model="settings.notifications.contracts">
                        <div class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                </div> -->

                <div class=" flex flex-row items-center justify-between justify-center">
                    <div class="flex flex-col">
                        <span class="font-bold">Message Notifications</span>
                        <span class="text-sm w-72 text-gray-400">Allow ApexTeks send you email notifications for messages.</span>
                    </div>
                    
                    <label class="inline-flex items-center cursor-pointer">
                        <input type="checkbox" value="" class="sr-only peer" @change="updateUserData" v-model="settings.notifications.messages">
                        <div class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                </div>

                <div class=" flex flex-row items-center justify-between justify-center">
                    <div class="flex flex-col">
                        <span class="font-bold">Email Notifications</span>
                        <span class="text-sm w-72 text-gray-400">Allow ApexTeks send you email notifications for updates and activities related to your account.</span>
                    </div>
                    
                    <label class="inline-flex items-center cursor-pointer">
                        <input type="checkbox" value="" class="sr-only peer" @change="updateUserData" v-model="settings.notifications.emails">
                        <div class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                </div>
            </form>

            <form @submit.prevent="updateUserData" v-if="active_tab == 3" class="section mb-3">
                <h1 class="font-bold mb-3 text-lg">Notification Settings</h1>
                <!-- <div class=" flex flex-row items-center justify-between justify-center">
                    <div class="flex flex-col">
                        <span class="font-bold">Contract Notifications</span>
                        <span class="text-sm w-72 text-gray-400">Allow ApexTeks send you email notifications for contract actions and updates.</span>
                    </div>
                    
                    <label class="inline-flex items-center cursor-pointer">
                        <input type="checkbox" value="" class="sr-only peer" @change="updateUserData" v-model="settings.notifications.contracts">
                        <div class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                </div> -->

                <div class=" flex flex-row items-center justify-between">
                    <div class="flex flex-col">
                        <span class="font-bold">Message Notifications</span>
                        <span class="text-sm w-72 text-gray-400">Allow ApexTeks send you email notifications for messages.</span>
                    </div>
                    
                    <label class="inline-flex items-center cursor-pointer">
                        <input type="checkbox" value="" class="sr-only peer" @change="updateUserData" v-model="settings.notifications.messages">
                        <div class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                </div>

                <div class=" flex flex-row items-center justify-between">
                    <div class="flex flex-col">
                        <span class="font-bold">Email Notifications</span>
                        <span class="text-sm w-72 text-gray-400">Allow ApexTeks send you email notifications for updates and activities related to your account.</span>
                    </div>
                    
                    <label class="inline-flex items-center cursor-pointer">
                        <input type="checkbox" value="" class="sr-only peer" @change="updateUserData" v-model="settings.notifications.emails">
                        <div class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                </div>
            </form>

             <!-- ID & VERIFICATION -->
             <form @submit.prevent="verifyUserNow"  v-if="active_tab == 4" class="section mb-3">
                
                <div class="mt-3">
                <!-- {{ verificationStatus }} -->
                    <h1 class="font-bold mb-3 text-xl capitalize">Identity verification - {{  verificationStatus }}</h1>
                    <div class="flex border dark:border-gray-600 rounded-xl p-5 flex-col">
                        <h3 class=" font-bold text-lg">Start your verification</h3>
                        <div class="flex flex-row">
                            <div>
                                <div></div>
                            </div>
                            <div class="relative flex flex-col gap-8 mt-8 w-full">
                                <div class="flex flex-row gap-3" v-for="step in verification_steps">
                                    <div :class="verificationStatus == 'verified' ? 'bg-green-500':'bg-blue-500'" class=" !size-8 min-w-[32px] rounded-full text-white flex justify-center items-center">
                                        <i v-if="verificationStatus == 'pending'" class="bi bi-exclamation"></i>
                                        <i v-if="verificationStatus == 'in progress'" class="bi bi-arrow-clockwise"></i>
                                        <i v-if="verificationStatus == 'verified'" class="bi bi-check"></i>
                                    </div>
                                    
                                    <div class="flex flex-col">
                                        <span>{{ step.title }}</span>
                                        <small>{{ step.subtitle }}</small>
                                    </div>
                                </div>
                                <div v-if="verificationStatus == 'pending'" class=" w-full flex flex-row justify-end items-center">
                                    <RouterLink :to="`/KYC/${user._id}/verification`">
                                        <button class="btn">Continue</button>
                                    </RouterLink>
                                </div>
                            </div>
                        </div>
                    </div>

                                  
                </div>
            </form>

          

            <div v-if="active_tab == 5" class="section mb-3">
                <h2 class=" text-xl font-bold">Wallets and Payments Settings</h2>
                <div class="flex flex-col gap-12">

                    <!-- CURRENT BALANCE -->
                    <div class="flex flex-col gap-3 bg-gray-500 bg-opacity-10 rounded-md p-5">
                        <p class="font-bold">Current Balance</p>
                        <Skeleton v-if="loading_wallet" height="50px" width="220px"/>
                        <div class="flex flex-row flex-wrap justify-between items-center">
                            <div class="text-2xl font-bold flex flex-row gap-1 items-center">
                                <div class="p-3 bg-gray-800 rounded-full flex justify-center items-center size-[40px] text-white">₦</div>
                                <span class="inline-block text-4xl"> {{ account_balance.toLocaleString() }}</span>
                            </div>
                            <button v-if="!withdrawal_step" type="button" @click="requested_withdrawal = !requested_withdrawal" :disabled="account_balance == 0" class="btn mt-3 md:mt-0"><i class="bi bi-cash-stack mr-3"></i>Request Withdrawal</button>
                        </div>
                    </div>

                    <!-- FUND WITHDRAWL MODAL -->
                    <span v-if="withdrawal_errors" class="text-red-500">{{ withdrawal_errors }}</span>
                    <div v-if="withdrawal_step" class="flex flex-col md:flex-row justify-between  px-5">
                        <!-- REQUEST WITHDRAWAL -->
                        <div class="flex flex-col gap-3 max-w-sm">
                            <h1 class="text-lg font-bold">Request Withdrawal</h1>

                            <!-- default withdrawal amounts -->
                            <div class="flex flex-row flex-wrap gap-3" >
                                <button type="button" :class="payout_options.amount == Math.round(account_balance/button) ? 'border-blue-500':''" class=" default_amounts" v-for="(button, index) in 4" @click="payout_options.amount = Math.round(account_balance/button) " :key="index"> ₦ {{ Math.round(account_balance/button).toLocaleString() }}</button>
                            </div>
                            <form @submit.prevent="withdrawFunds_Stage_1()" v-if="withdrawal_stage == 0" class="flex flex-col gap-3">
                                <div class="flex relative">
                                    <CustomFormInput :title="'withdrawal_amount'" v-model="payout_options.amount" class=" mt-4 w-full"/>
                                    <button type="button" 
                                    v-if="banks.length <= 0"
                                    @click="withdrawFunds_Stage_1" class="btn w-fit absolute right-0 bottom-1" 
                                    :disabled="processing_withdraw || payout_options.amount == 0">
                                        <span v-if="processing_withdraw" class=" app_spinner"></span>
                                        <span v-else><i class="bi bi-arrow-right"></i></span>
                                    </button>
                                </div>
                            </form>
                    <!-- {{  payout_options }} -->


                            <form @submit.prevent="withdrawFunds" v-if="true" class="flex flex-col gap-3 mt-4">
                                <div class="" v-if="banks.length > 0">
                                    <CustomFormInput :title="'Account_number'" v-model="payout_options.account_number" class=" "/>
                                    <CustomFormInput :title="'account_name'" v-model="user.username" class=" mt-4 w-full"/>
                                    
                                
                                    <select class=" bg-inherit py-3 border-b text-gray-500 border-b-gray-500 mt-4" v-model="payout_options.bank_name" @change="setBankCode">
                                        <option value="" disabled>Select Bank</option>
                                        <option v-for="bank in banks" :key="bank.name" :value="bank.name">
                                            {{ bank.name }}
                                        </option>
                                    </select>

                                    <!-- PREVIOUS BENEFICIARY -->
                                    <div v-if="user.settings.bank" class="flex flex-row flex-wrap gap-3 mt-6" >
                                        <h1 class="text-lg font-bold">Previously used bank</h1>
                                        <div :class="payout_options.bank_code == user.settings.bank.code ? 'border-blue-500 bg-blue-500 bg-opacity-10':''" class=" border p-2 rounded-lg dark:border-gray-600 hover:border-blue-500 flex flex-row items-center gap-3 w-full cursor-pointer relative" @click="selectDefaultBank">
                                            <div class=" size-12 rounded-md bg-green-500 flex justify-center items-center text-white text-xl"><i class="bi bi-bank2"></i></div>
                                            <div class="flex flex-col text-gray-500">
                                                <span class="font-bold text-lg">{{ user.settings.bank.name }}</span>
                                                <span class="text-sm">{{ user.settings.bank.account_name}} - {{ user.settings.bank.account_number }}</span>
                                            </div>
                                            <i class="bi bi-arrow-right  absolute right-5 top-auto"></i>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>

                        <!-- WITHDRAWAL SUMMARY -->
                        <div class="max-w-sm w-full flex flex-col gap-3 md:w-[50%] mt-12 md:mt-0">
                            <h1 class="text-lg font-bold">Withdrawal Summary</h1>
                            <div class="flex flex-col gap-3 ">
                                <div class="flex flex-row justify-between gap-3 ">
                                    <span>Account Number</span>
                                    <span class="font-bold text-right">{{  payout_options.account_number }}</span>
                                </div>
                                <div class="flex flex-row justify-between">
                                    <span>Withdrawal Amount</span>
                                    <span class="font-bold text-lg text-right">NGN {{  payout_options.amount }}</span>
                                </div>
                                <div class="flex flex-row justify-between">
                                    <span>Balance After Withdrawal</span>
                                    <span class="font-bold text-right">NGN {{  (account_balance - payout_options.amount).toLocaleString() }}</span>
                                </div>
                            </div>

                            <button v-if="banks.length > 0" type="submit" class="btn w-full" :disabled="processing_withdraw || banks.length == 0 || payout_options.amount == 0 || payout_options.account_number == '' || payout_options.bank_name == ''" @click="withdrawFunds">
                                <span v-if="processing_withdraw"><span class="app_spinner mr-3"></span>processing...</span>
                                <span v-else>Withdraw Funds</span>
                            </button>

                        </div>
                    </div>

                    
                <!--  RECENT TRANSACTIONS -->
                <div class="flex flex-col gap-3 mt-12 p-3">
                    <h1 class=" font-bold text-lg">Recent Transactions</h1>
                    <div class="flex flex-col overflow-x-auto">
                        <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                            <thead class="bg-gray-50 dark:bg-gray-700">
                                <tr>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Amount (N)
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Date
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Narration
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Type
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                                <tr v-for="transaction in transactions" :key="transaction.id">
                                    <td v-if="transaction.amount" class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-200">
                                    #{{ transaction.amount.toLocaleString() }}
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                                        {{ readableDateFormat(transaction.date) }}
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                                        {{ transaction.naration }}
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                                        {{ transaction.type }}
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                                        {{ transaction.status }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <span class=" underline text-blue-500">see more</span>
                </div>


                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import JobDetailCard from '@/components/JobDetailCard.vue';
import TemplateView from '../TemplateView.vue';
import ContractStatus from '@/components/ContractStatus.vue';
import FullPageLoading from '@/components/FullPageLoading.vue'
import PageTitle from '@/components/PageTitle.vue';
import DismissableAlert from '@/components/DismissableAlert.vue';

import Password from 'primevue/password'
import Alert from '@/components/Alert.vue';
import Toast from 'primevue/toast';

import { useToast } from 'vue-toastification';
import CustomFormInput from '@/components/CustomFormInput.vue';
import Skeleton from 'primevue/skeleton';
import { format } from 'date-fns';


export default {
    name: "ClientSettingsPageView",
    components: { 
        TemplateView, 
        ContractStatus, 
        PageTitle, 
        FullPageLoading, 
        DismissableAlert, 
        Password, 
        Alert,
        Toast, 
        CustomFormInput,
        Skeleton,
    },
    data(){
        return{
            format,
            verification_step: 1,
            verification_steps: [
                { title: 'Show us a government-issued ID', subtitle: "we'll check that the info in your ID  matches the one you provided in your profile."},
                { title: 'Appear on camera', subtitle: "To show us it's really you, take an automatic selfie."},
                { title: "Submit for Identity review", subtitle: "To show us it's really you, take an automatic selfie."},
            ],
            toast: useToast(),
            alerts: [],
            show_alert: false,
            alert_type: '',
            alert_message: '',

            loading: false,
            contracts: '',
            headers: {
                Authorization: `JWT ${localStorage.getItem('life-gaurd')}`
            },
            current_mode: '',
            active_tab: 2,

            user: {
                profile:{
                    phone: '',
                }
            },

            security: {
                password: '',
                new_password: '',
                new_password_confirmation: '',
            },

            password_errors: '',

            settings:{
                bank: {
                    name: '',
                    sort_code: '',
                    account_number: '',
                },
                card: {
                    number: '',
                    date: '',
                    cvv: ''
                },
                notifications:{
                    contracts: true,
                    messages: true,
                    email: true,
                },
                KYC: {
                    NIN_number: '',
                    is_verified: true,
                },
                profile_visibility: '',
            },
            passHint: false,
            rules: [
				{ message:'One lowercase letter required.', regex:/[a-z]+/ },
				{ message:"One uppercase letter required.",  regex:/[A-Z]+/ },
				{ message:"8 characters minimum.", regex:/.{8,}/ },
				{ message:"One number required.", regex:/[0-9]+/ }
			],
            wallet: {},
            processing_withdraw: false,
            withdrawal_stage: 0,
            withdrawal_amount: 0,
            account_number: '',
            payout_url: '',

            payout_bank: {
                name: '',
                code: '',
            },

            payout_options: {
                payout_url: '',
                account_number: '',
                bank_code: '',
                bank_name: '',
                amount: '',
            },

            withdraw_requested: false,
            withdrawal_step: false,
            requested_withdrawal: false,
            password_approved: false,
            withdrawal_errors: '',
            fwp_password: '',
            banks: [],
        }
    },
    methods:{
        readableDateFormat(date){
            return this.format( new Date(date), "EEEE do MMMM yyyy")
        },

        setBankCode() {
           const bank = this.banks.find(b => b.name === this.payout_options.bank_name);
            this.payout_options.bank_code = bank ? bank.code : '';
            console.log("payout options", this.payout_options);
        },

        async checkPassword(){
            try{
                const headers = this.headers;
                const response = await axios.post(`/password/check`, {password: this.fwp_password}, { headers });
                console.log("from pass checker: ", response);
                this.requested_withdrawal = false,
                this.withdrawal_step = response.data.authenticated;
                this.fwp_password = ''
                // this.toast.info(response.data.message);
            }catch(error){
                // console.log("problem with pass checker: ", error)
                this.requested_withdrawal = false,
                this.fwp_password = ''
                this.toast.error(error.response.data.message);
            }
        },

        async setupNewPassword() {
            try {
                const headers = this.headers;
                const response = await axios.post('/api/password/setup_new', {
                    password: this.security.new_password,
                    password_confirmation: this.security.new_password_confirmation
                }, { headers });
                this.toast.success(response.data.message);
                this.security.new_password = '';
                this.security.new_password_confirmation = '';
            } catch (error) {
                this.toast.error(error.response.data.message);
            }
        },

        async getUserData(){
            this.loading = true;
            const headers = this.headers;
            try{    
                const response = await axios.get(`/user`, { headers });
                console.log(response)
                this.user = response.data.user;
                this.user.KYC = response.data.KYC;

                if(this.user.settings){
                    this.settings.profile_visibility = this.user.settings.profile_visibility;
                   /*  if(this.user.settings.bank){
                        this.settings.bank = this.user.settings.bank
                    }
                    if(this.user.settings.card){
                        this.settings.bank = this.user.settings.bank
                    } */
                    if(this.user.settings.notifications){
                        this.settings.notifications = this.user.settings.notifications
                    }
                    if(this.user.settings.KYC){
                        this.settings.KYC = this.user.settings.KYC
                    }
                }
                
                
                this.loading = false;
            }catch(error){
                console.log("error getting user details: ", error);
                this.loading = true;
            }
        },

        async updateUserData(){
            this.loading = true;
            const headers = this.headers;
            try{
                const user = this.user;
                user.settings = this.settings;
                const response = await axios.patch(`/user/profile`, user, { headers });
                // console.log("set successful: ", response);
                this.toast.success(response.data.message)
                // this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'settings updated successfully!', life: 3000 });
                this.loading = false;
            }catch(error){
                // console.log("error updating user profile: ", error);
                this.toast.error(error.response.data.message)
                this.loading = false;
            }
        },

         // this takes care of resetting and adding new passwords
         async updatePassword(new_password){
            if(new_password == true){
                try {
                    const headers = this.headers;
                    const response = await axios.post('/password/setup_new', {
                        password: this.security.new_password,
                        password_confirmation: this.security.new_password_confirmation
                    }, { headers });
                    this.toast.success(response.data.message);
                    this.security.new_password = '';
                    this.security.new_password_confirmation = '';
                    setTimeout(()=>{
                       window.location.reload(); 
                    }, 3000);
                } catch (error) {
                    this.toast.error(error.response.data.message);
                }
            } else {
                
                try{
                    if(this.security.password == '' || this.security.new_password == '' || this.security.new_password_confirmation == ''){
                        this.toast.error("invalid data provided!");
                    } else {
                    const headers = this.headers;
                    this.loading = true;
                    const response = await axios.post(`${this.api_url}/user/password/change`, this.security, { headers });
                    console.log("password response: ", response);
                    alert("Password Updated Successfully!")
                    this.loading = false;
                    this.toast.success(response.data.message);
                    }
                }catch(error){
                    // console.log("error changing password: ", error);
                    this.toast.error(error.response.data.message);
                    this.password_errors = error.response.data.message;
                    this.loading = false;
                };
            }
        },

         async withdrawFunds_Stage_1(){
            try{
                this.processing_withdraw = true;
                const response = await axios.post(`/wallets/payouts/initiate_withdrawal`, 
                { amount: this.payout_options.amount }, 
                { headers: this.headers });
                
                this.withdrawal_stage = 1;
                this.payout_options.payout_url = response.data.bankLists.payout_url;
                this.banks = response.data.bankLists.detail.data;
                console.log("FUNDS ALERT: ", response);
                this.processing_withdraw = false;
                
            }catch(error){
                // this.withdrawal_errors = error.response.data.message;
                this.withdrawal_errors = "error initiating withdrawal";
                this.withdrawal_step = false;
                console.log("ERROR IN WITHDRAWAL STAGE 1: ", error);
                this.processing_withdraw = false;
                this.toast.error(error.response.data.message);
            }
        },

        async withdrawFunds(){
            try{
                this.processing_withdraw = true;
                const response = await axios.post(`/wallets/payouts/employer_withdraw_funds`, 
                     this.payout_options , 
                    { headers: this.headers }
                );

                console.log("withdrawing funds: ", { payout_options: this.payout_options });

                this.processing_withdraw = false;
                this.withdraw_requested = true;
                console.log("WITHDRAWAL INITIATED: ", response);
                
            }catch(error){
                console.log("error with withdrawal: ", error);
                this.processing_withdraw = false;
                this.withdrawal_errors = error.response.data.message;
                this.toast.error(error.response.data.message);
            }
        },

        async getUserWallet(){
            try{
                this.loading_wallet = true;
                const headers = this.headers;
                const response  = await axios.get(`/wallets/info`, { headers });
                console.log("user wallet: ", response.data.wallet);
                this.wallet = response.data.wallet;
                this.account_balance = response.data.wallet.balance;
                // get only first five wallet transactions...
                this.transactions = response.data.wallet.transactions.reverse();
                this.loading_wallet = false;
            }catch(error){
                this.loading_wallet = false;
                console.error("erro getting user wallet: ", error);
            }
        },

       
    },
    computed:{
        verificationStatus(){
            if(this.user){
                return this.user.KYC.status;
            }
        },

        passwordValidation () {
			let errors = []
			for (let condition of this.rules) {
				if (!condition.regex.test(this.security.new_password)) {
					errors.push(condition.message)
				}
			}
			if (errors.length === 0) {
				return { valid:true, errors }
			} else {
				return { valid:false, errors }
			}
		}
    },

    created(){
        this.getUserData();
        this.getUserWallet();

    },

    updated(){
        
        if(this.$route.query.tab == 'profile'){
            this.active_tab = 2;
        } else if (this.$route.query.tab == 'notifications'){
            this.active_tab = 3
        } else if(this.$route.query.tab == 'verifications'){
            this.active_tab = 4;
        } else if(this.$route.query.tab == 'wallets'){
            this.active_tab = 5;
        }
        
    },
}

</script>
<style scoped>
    .section{
        @apply h-full flex flex-col p-2 gap-5
    }

    .tab_button{
        @apply p-3
    }

    .active_tab{
        @apply  border-tz_blue border-b-4
    }

    .theme-toggle{
        @apply  w-24 h-24 p-5 flex justify-center items-center rounded-xl border-4 dark:border-gray-400 dark:bg-tz_light_blue hover:border-blue-500 hover:bg-tz_dark_blue;
    }
    .active_theme{
        @apply border-blue-500 bg-tz_light_blue 
    }
</style>