<template>
   <div>
    <header>
        <p>Last Updated: 16/12/2024</p>
    </header>

    <section>
        <p>Welcome to APEX TECHNOLOGIES. This Privacy Policy is designed to inform you about the types of information we collect, how we use and protect that information, and your choices regarding your information. By using our website, you agree to the terms of this Privacy Policy.</p>
    </section>

    <section>
        <h2>Information We Collect</h2>
        <h3>Personal Information:</h3>
        <ul>
            <li>Name, Date of Birth</li>
            <li>Contact Information (email address, phone number, address)</li>
            <li>Resume/CV details (education, work experience, skills, etc.)</li>
            <li>Professional memberships and certifications</li>
            <li>Employment preferences and goals</li>
        </ul>
        
        <h3>Sensitive Information:</h3>
        <p>APEX TECHNOLOGIES may collect certain sensitive information with your explicit consent, such as disability status or other relevant information for job accommodation.</p>
        
        <h3>Usage Information:</h3>
        <ul>
            <li>IP address, browser type, and device information</li>
            <li>Usage patterns and interactions with Apex Technologies</li>
        </ul>
    </section>

    <section>
        <h2>How We Use Your Information</h2>
        <h3>Job Matching:</h3>
        <ul>
            <li>Matching your profile with relevant job opportunities</li>
            <li>Providing personalized job recommendations</li>
        </ul>
        
        <h3>Communication:</h3>
        <ul>
            <li>Sending job alerts, newsletters, and updates</li>
            <li>Responding to inquiries and providing support</li>
        </ul>
        
        <h3>Improving Services:</h3>
        <ul>
            <li>Analyzing user behavior to enhance and customize user experience</li>
            <li>Conducting research and analysis to improve our services</li>
        </ul>
        
        <h3>Legal Compliance:</h3>
        <ul>
            <li>Complying with applicable laws and regulations</li>
            <li>Protecting our legal rights and preventing fraud</li>
        </ul>
    </section>

    <section>
        <h2>Data Security</h2>
        <p>APEX TECHNOLOGIES will implement technical and organizational measures to protect your information from unauthorized access, disclosure, alteration, and destruction. We will regularly review and update our security practices to ensure the ongoing confidentiality and integrity of your data.</p>
    </section>

    <section>
        <h2>Information Sharing</h2>
        <h3>Employers and Recruiters:</h3>
        <p>APEX TECHNOLOGIES may share your profile and resume with potential employers or recruiters only with your explicit consent.</p>
        
        <h3>Service Providers:</h3>
        <p>APEX TECHNOLOGIES may engage third-party service providers to assist in our operations, and we may share some of your information with them subject to strict confidentiality obligations.</p>
        
        <h3>Legal Obligations:</h3>
        <p>APEX TECHNOLOGIES may disclose information in response to legal requests, court orders, or government regulations.</p>
    </section>

    <section>
        <h2>Your Choices</h2>
        <h3>Access and Control:</h3>
        <ul>
            <li>You have the right to access, correct, or delete your personal information from our website at any time.</li>
            <li>You have the right to opt-out of communications or withdraw consent earlier given.</li>
        </ul>
        
        <h3>Cookie Settings:</h3>
        <p>You have the right to manage your cookie preferences through your browser settings.</p>
    </section>

    <section>
        <h2>Children's Privacy</h2>
        <p>Apex Technologies services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children.</p>
    </section>

    <section>
        <h2>Changes to this Privacy Policy</h2>
        <p>We reserve the right to update and modify this Privacy Policy. We will notify you of significant changes through our website or other means, including but not limited to emails.</p>
    </section>

    <section>
        <h2>Contact Information</h2>
        <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:support@ApexTeks.com">support@ApexTeks.com</a>.</p>
    </section>

    <section>
        <h2>Your Rights</h2>
        <ul>
            <li><strong>Right to Access:</strong> Request access to the personal information we hold about you.</li>
            <li><strong>Right to Rectification:</strong> Request correction of any inaccurate or incomplete personal information.</li>
            <li><strong>Right to Erasure:</strong> Request deletion of your personal information, subject to certain exceptions (e.g., compliance with legal obligations).</li>
            <li><strong>Right to Restrict Processing:</strong> Request restrictions on processing your personal information under certain conditions.</li>
            <li><strong>Right to Data Portability:</strong> Request the transfer of your personal information to another service provider in a structured, commonly used, and machine-readable format.</li>
            <li><strong>Right to Withdraw Consent:</strong> Withdraw your consent to process your personal information at any time.</li>
            <li><strong>Right to Lodge a Complaint:</strong> Lodge a complaint with a supervisory authority if you believe our processing violates applicable laws.</li>
        </ul>
    </section>

    <section>
        <h2>Identity Verification Process</h2>
        <p>Our partner, Dojah, handles all identity management and verification. For more details about Dojah, please visit their website at <a class="underline text-blue-500" href="https://www.dojah.io" target="_blank">dojah.io</a>.</p>
    </section>

    <footer class="contact-info">
        <p><strong>Apex Technologies</strong> (A Company of DIGITALWAVE SOFT-TECH INNOVATION LTD)</p>
        <p>Email: <a href="mailto:support@ApexTeks.com">support@ApexTeks.com</a></p>
        <p>Effective Date: 06/12/2024</p>
    </footer>

   </div>
</template>

<script>
    export default {
        name: "PrivacyPolicyText",
    }
</script>

<style scoped>
    h3{
        @apply font-bold text-lg mt-3 text-blue-500
    }

    h2{
        @apply font-bold text-xl mt-3 text-blue-700
    }

    p{
        @apply font-medium
    }

    /* p{
        @apply p-4
    } */
</style>