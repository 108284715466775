<template>
    <div>

    <header>
        <p>Effective Date: 16/12/2024</p>
    </header>

    <section id="acceptance-of-terms">
        <h2>1. Acceptance of Terms</h2>
        <ul>
            <li>1.1. By accessing and using Apex Technologies, you agree to comply with and be bound by these terms and conditions.</li>
            <li>1.2. If you do not agree with any part of these terms, you must not use our services.</li>
        </ul>
    </section>

    <section id="user-eligibility">
        <h2>2. User Eligibility</h2>
        <ul>
            <li>2.1. You must be at least 18 years old to use our services.</li>
            <li>2.2. By using our services, you represent and warrant that you have the right, authority, and legal capacity to enter into this agreement.</li>
        </ul>
    </section>

    <section id="registration">
        <h2>3. Registration and User Accounts</h2>
        <ul>
            <li>3.1. You are required to create an account to access most of the features of our website.</li>
            <li>3.2. You are responsible for maintaining the confidentiality of your account information, and you agree to notify us immediately of any unauthorized use of your account.</li>
        </ul>
    </section>

    <section id="job-listings">
        <h2>4. Job Listings and Applications</h2>
        <ul>
            <li>4.1. Apex Technologies provides a platform for employers to post job listings and for Freelancers to apply.</li>
            <li>4.2. We do not guarantee the accuracy, completeness, or reliability of job listings or the suitability of applicants.</li>
            <li>4.3. Employers and freelancers are responsible for conducting their own due diligence before entering into any employment agreements.</li>
        </ul>
    </section>

    <section id="user-content">
        <h2>5. User Content</h2>
        <ul>
            <li>5.1. Users are solely responsible for the content they post on Apex Technologies.</li>
            <li>5.2. By submitting content, you grant us a non-exclusive, royalty-free, worldwide, perpetual license to use, reproduce, and distribute the content.</li>
            <li>5.3. The content on the Website is provided for general information purposes only. It may be subject to change without notice.</li>
            <li>5.4. You acknowledge and agree that Apex Technologies reserves the right to remove or modify any content on the Website for any reason, without prior notice.</li>
        </ul>
    </section>

    <section id="privacy-policy">
        <h2>6. Privacy Policy</h2>
        <p>Our privacy policy outlines how we collect, use, and protect your personal information. By using our services, you agree to our privacy policy.</p>
    </section>

    <section id="termination">
        <h2>7. Termination of Accounts</h2>
        <p>ApexTeks reserves the right to suspend or terminate your account and access to our platform at any time for any reason, including but not limited to violation of these Terms:</p>
        <ul>
            <li>7.1. Fraudulent activity</li>
            <li>7.2. Misuse of privilege</li>
            <li>7.3. Misuse of information related to our platform</li>
            <li>7.4. Posting fake or fraudulent jobs</li>
            <li>7.5. Impersonation</li>
            <li>7.6. Time fraud relating to our TaskWatch</li>
            <li>7.7. Any form of abuse towards an employer or freelancers upon investigation</li>
            <li>7.8. Unauthorized use of company information</li>
            <li>7.9. Unauthorized use of private information</li>
            <li>7.10. Failure to pay applicable fees</li>
            <li>7.11. Attempting NIN fraud</li>
        </ul>
        <p>Upon termination, you agree to cease all use of ApexTeks's services and promptly settle any outstanding fees or obligations.</p>
    </section>

    <section id="disclaimers">
        <h2>8. Disclaimers</h2>
        <ul>
            <li>8.1. Apex Technologies is provided "as is" without any warranties, express or implied.</li>
            <li>8.2. We do not guarantee the availability, accuracy, or reliability of our services.</li>
        </ul>
    </section>

    <section id="liability">
        <h2>9. Limitation of Liability</h2>
        <p>In no event shall Apex Technologies be liable for any indirect, consequential, or incidental damage arising out of or in connection with the use of our services.</p>
    </section>

    <section id="governing-law">
        <h2>10. Governing Law</h2>
        <ul>
            <li>10.1. These terms are governed by and construed in accordance with the laws of the Federal Republic of Nigeria.</li>
            <li>10.2. Any disputes relating to these terms will be subject to the exclusive jurisdiction of Nigerian courts.</li>
        </ul>
    </section>

    <section id="intellectual-property">
        <h2>11. Intellectual Property</h2>
        <ul>
            <li>11.1. All content on the Website is the property of Apex Technologies and is protected by intellectual property laws.</li>
            <li>11.2. You may not reproduce, distribute, display, or create derivative works of any content without express written consent.</li>
        </ul>
    </section>

    <section id="user-conduct">
        <h2>12. User Conduct</h2>
        <ul>
            <li>12.1. You agree not to use the Website for any unlawful purpose or in any way that could violate these Terms and Conditions.</li>
            <li>12.2. You will not engage in any conduct that restricts or inhibits any other user from using or enjoying the Website.</li>
        </ul>
    </section>

    <section id="changes-to-terms">
        <h2>13. Changes to Terms</h2>
        <p>We reserve the right to update or modify these terms at any time without prior notice. Your continued use of our services after any changes constitutes acceptance of the revised terms.</p>
    </section>

    <footer>
        <p>If you have any questions about these Terms and Conditions, please contact us at <a href="mailto:support@ApexTeks.com">support@ApexTeks.com</a></p>
        <p>Apex Technologies (A Company of DIGITALWAVE SOFT-TECH INNOVATION LTD)</p>
    </footer>
    <!-- </footer> -->
    </div>
</template>

<script>
    export default {
        name: "TermsAndConditionText"
    }
</script>

<style scoped>
    h3{
        @apply font-bold text-lg mt-3 text-blue-500
    }

    h2{
        @apply font-bold text-xl mt-3 text-blue-700
    }

    p{
        @apply font-medium
    }

    a{
        @apply text-orange-400
    }
</style>