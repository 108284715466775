<template>
    <div class=" bg-black text-white p-5">
        <!-- <div class="flex flex-col-reverse gap-10 md:flex-row justify-between bg-white max-w-screen-2xl" data-aos="fade-up" data-aos-delay="200"> -->
 
            <div class="container flex flex-col md:flex-row justify-between py-14 w-full mx-auto gap-32">
                <div class="flex flex-row justify-between items-start md:w-[300px]">
                    <ul class="list">
                        <li class="list_header"><img src="../../public/apex-tek-white.svg" class=" h-[60px]"></li>
                        <li class="text-sm">
                            In Nigeria, the burgeoning tech industry holds immense promise for both companies seeking skilled professionals and individuals equipped with tech expertise. However, amidst this potential lies a challenge: unemployment among tech professionals persists, even as companies struggle to find the right talent for their projects. Enters Apex Technologies, a pioneering platform on a mission to revolutionize the tech employment landscape in Nigeria. 
                            Whether you're a company looking to expand your tech team or an IT professional seeking your next challenge, Apex Technologies invites you to join our growing community and be part of the future of tech employment in Nigeria. Together, we can unlock the full potential of Nigeria's tech industry and pave the way for a brighter, more prosperous future. 
                        </li>
                    </ul>
                </div>
                
                <div class="flex flex-row flex-wrap gap-1 justify-between flex-1">
                    <ul class="list">
                        <li class="list_header">Job by categories</li>
                        <li v-for="(job, index) in jobCategories.splice(0, 12)" :key="index">
                            <RouterLink to="/jobs">
                                {{ job.name }}
                            </RouterLink>
                        </li>
                    </ul>

                    <ul class="list">
                        <li class="list_header">Job by location</li>
                        <li v-for="(state, index) in availableStates" :key="index">
                            <RouterLink to="/jobs">
                                {{ `Jobs in ${state}` }}
                            </RouterLink>
                        </li>
                    </ul>

                    <ul class="list">
                        <li class="list_header">Help & Support</li>
                        <RouterLink to='/privacy'><li>Privacy Policy</li></RouterLink>
                        <RouterLink to='/FAQ'><li>FAQ</li></RouterLink>
                        <RouterLink to='/register/client'><li>Hire on ApexTeks</li></RouterLink>
                        <!-- <RouterLink to='/vision'><li>Our Vision</li></RouterLink> -->
                        <!-- <li>Team</li> -->
                        <!-- <li>Careers</li> -->
                    </ul>

                    <ul class="list">
                        <li class="list_header">Quick Links</li>
                        <RouterLink to='/about'><li>About Us</li></RouterLink>
                        <!-- <RouterLink to='/vision'><li>Our Vision</li></RouterLink> -->
                        <!-- <li>Team</li> -->
                        <!-- <li>Careers</li> -->
                    </ul>
                </div>
               
             
            </div>
          
           

            
    <!-- </div> -->
     <!-- MINI FOOTER STARTS HERE -->
    <!--  <div class=" p-3 w-full bg-[#EDEFF3] justify-center items-center text-sm flex flex-row gap-5">
            
            <div class="flex-row gap-3 hidden md:flex text-center justify-center items-center">
                <span>
                    <RouterLink to='/about'>About Us</RouterLink>
                </span>
                <span>
                    <RouterLink to='/privacy'>Privacy Policy</RouterLink>
                </span>
                <span>
                    <RouterLink to='/privacy'>Cookie Policy</RouterLink>
                </span>
                <span>
                    <RouterLink to='/privacy'>Billing/Payment T&Cs</RouterLink>
                </span>
                <span> 
                    <RouterLink to='/privacy'>Use of software T&Cs</RouterLink>
                </span>
                <span>
                    <RouterLink to='/FAQ'>FAQs</RouterLink>
                </span>
            </div>
            <p>&copy;2024 ApexTeks all rights reserved.</p>
    </div> -->
    
    <!-- MINI FOOTER ENDS HERE -->
      
     </div>
     <div class=" flex flex-row justify-end w-full items-center p-5 bg-[#0c1e2b]">
        <div class="flex flex-row justify-between items-center container mx-auto text-gray-400">
            <p class=" text-white">&copy;2024 ApexTeks all rights reserved.</p>

            <div class=" flex flex-row gap-8">
                <a href="#">
                    <i class="bi bi-linkedin"></i>
                </a>

                <a href="https://twitter.com/ApexTeks">
                    <i class="bi bi-twitter-x"></i>
                </a>

                <a href="https://instagram.com/apexteks">
                    <i class="bi bi-instagram"></i>
                </a>
            </div>
           
        </div>
    </div>
    <p><strong>Apex Technologies</strong> (A Company of DIGITALWAVE SOFT-TECH INNOVATION LTD)</p>

</template>
<script>
import SiteLogo from './SiteLogo.vue';
import jobCategories from '../utils/jobCategories.json';

export default {
    name: "Footer",
    components: { SiteLogo },
    data(){
        return{
            jobCategories,

            availableStates: [
            "Lagos",
            "Abuja",
            "Rivers",
            "Kano",
            "Oyo",
            "Enugu",
            ],
        }
    }
}
</script>
<style scoped>
.section_title{
    @apply font-bold
}

.list{
    @apply text-left text-sm
}
.list_header{
    @apply font-bold text-xl capitalize mb-3
}

li{
    @apply py-2
}
   
</style>