<template>
    <div class="wave-group">
        <input required="" 
        type="text" 
        class="input uppercase"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @blur="$emit('blur', $event)"
        @focus="$emit('focus', $event)"
        >
        <span class="bar"></span>
        <label class="label">
            <span class="label-char uppercase" v-for="(text, index) in title" :key="index" :style="`--index: --${text}`">{{ text }}</span>
            <!-- <span class="label-char" v-for="(text, index) in title" :key="index" :style="`--index: ${index}`">{{ text }}</span> -->
        </label>
    </div>
</template>

<script>

import { ref } from 'vue';

export default {
    name: "CustomFormInput",
    props: {
        title: {
            type: String,
            required: true
        },
        modelValue: {
            type: [String, Number],
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const inputValue = ref(props.modelValue);

      /*   const updateValue = (event) => {
            emit('update:modelValue', event.target.value);
        }; */

        return {
            inputValue,
            // updateValue
        };
    }
}
</script>
<style scoped>
/* From Uiverse.io by AbanoubMagdy1 */ 
.wave-group {
  position: relative;
}

.wave-group .input {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #515151;
  background: transparent;
}

.wave-group .input:focus {
  outline: none;
}

.wave-group .label {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  display: flex;
}

.wave-group .label-char {
  transition: 0.2s ease all;
  transition-delay: calc(var(--index) * .05s);
}

.wave-group .input:focus ~ label .label-char,
.wave-group .input:valid ~ label .label-char {
  transform: translateY(-20px);
  font-size: 10px;
  color: #5264AE;
}

.wave-group .bar {
  position: relative;
  display: block;
  /* width: 200px; */
}

.wave-group .bar:before,.wave-group .bar:after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #5264AE;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.wave-group .bar:before {
  left: 50%;
}

.wave-group .bar:after {
  right: 50%;
}

.wave-group .input:focus ~ .bar:before,
.wave-group .input:focus ~ .bar:after {
  width: 50%;
}


</style>